<template>

  <div id="shop_products">
    <div class="row">
      <div class="col-6"><h1>Продукция магазина</h1></div>
      <div class="col-6">
        <p style="text-align: right; padding: 10px;">
          <button class="createProductButton">
            <router-link to="/create-shop-product">Создать описание для продукта</router-link>
          </button>
        </p>
      </div>
      <div class="row">
        <div v-if="statusDelete" class="statusDelete">{{ statusDelete }}</div>
        <table class="table table-striped table-hover" style="font-size: 12px;">
          <thead>
          <tr>
            <th scope="col">Наименование</th>
            <th scope="col">Категория</th>
            <th scope="col">URL</th>
            <th scope="col">Изменить</th>
            <th scope="col">Удалить</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="product in shopProducts"  :key="product._id">
            <td>{{ product.name }}</td>
            <td>{{ product.parent_category }}</td>
            <td>{{ product.slug }}</td>
            <td><router-link :to="{name: 'edit_shop_product', params: { idProduct: product._id } }"><button class="editProduct">Изменить</button></router-link></td>
            <td><button class="deleteProduct" @click="deleteProduct(product._id)">Удалить</button></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <button @click="reqB24">B24 inject</button><br><br>
        <pre>
          {{ response_b24 }}
        </pre>
      </div>


    </div>

  </div>

</template>

<script>
import Axios from "axios";

export default {
  name: "shop_products",
  data() {
    return {
      shopProducts: null,
      searchQuery: null,
      searchQueryName: null,
      statusDelete: null,
      response_b24: null,
    }
  },
  methods: {
    async reqB24 () {

      let data = {
        fields: {
          'TITLE': 'Заказ №1008 - (ssr.partner.su)',
          'FIO_FL': 'Фамилия Имя и Отчество... Кто додумался сделать 3х поля?!',
          'NAME': 'Имя Лида(ФИО)',
          'STATUS_ID': 'NEW',
          'SOURCE_ID': 6,
          'OPENED': 'Y',
          'EMAIL': [ { "VALUE": 'mail@mail.ru', "VALUE_TYPE": "WORK" } ],
          'UF_CRM_1611783971': 'Это адрес доставки?',
          'UF_CRM_1611784034': '77777777777i',
          'UF_CRM_1611784060': '77000000000k',
          'UF_CRM_1611789349': 'Это адрес доставки 2 ?',
          'ADDRESS_2': 'Сюда можно ЮР адрес ;-)',
          'PHONE': [ { "VALUE": "+79101234567", "VALUE_TYPE": "WORK" } ],
          'COMMENTS': 'Комментарий пользователя (тест значение)',
          'ASSIGNED_BY_ID': 935,
          'CURRENCY_ID': 'RUB',
          'COMPANY_TITLE': 'Тест имя компании',
        },
        params: { "REGISTER_SONET_EVENT": "Y" }
      };

      // Создание лида
      let url = 'https://portal.partner.su/rest/1011/5sl31smjpbk4ixru/crm.lead.add.json'
      const queryB24 = await Axios.post(url,data);
      //this.response_b24 = queryB24.data.result;

      // Привязка к нему продукции
      let dataProducts = {
          'id': queryB24.data.result,
          'rows': [
            { "PRODUCT_NAME": 'Шурупы', "PRICE": 100.00, "QUANTITY": 2 },
            { "PRODUCT_NAME": 'Гайки', "PRICE": 500.00, "QUANTITY": 1 }
          ],
      };
      let urlProducts = 'https://portal.partner.su/rest/1011/bo95txp0vahhlbvp/crm.lead.productrows.set.json';
      const queryB24products = await Axios.post(urlProducts,dataProducts);

      this.response_b24 = queryB24products;


    },
    async fetchProducts() {
      let myToken = this.$store.state.userInfo.token;

      let axiosConfig = {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': 'http://localhost:3000/',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'Authorization' : `Bearer ${myToken}`
        }
      }

      Axios
          .get('http://localhost:3000/shop/products/', axiosConfig)
          .then((response) => {
            this.shopProducts = response.data.products_list;
          })
          .catch(error => {
            console.log(error);
            this.errored = true;
          })
          .finally(() => (this.loading = false));
    },
    deleteProduct(id) {
      if(confirm("Вы уверены что хотите удалить товар?")){

        let myToken = this.$store.state.userInfo.token;

        Axios
            .post('http://localhost:3000/shop/delete-product/',
                {
                  'deleteProductId': id,
                },
                {
                  withCredentials: true,
                  headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': 'http://localhost:3000/',
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Authorization' : `Bearer ${myToken}`
                  }
                }

            )
            .then((response) => {
              this.statusDelete = response.data.answer;
              this.fetchProducts();
            });

      }
    }
  },
  created() {
    this.fetchProducts();
  }
}
</script>

<style scoped>
.createProductButton {
  padding: 8px;
  border:0px;
  border-radius: 10px;
  color: #ffffff;
  background: green;
}
.createProductButton a {
  color: #ffffff;
  text-decoration: none;
}
.createProductButton:hover {
  background: darkgreen;
}
.statusDelete {
  background: red;
  color: #FFFFFF;
  padding: 6px;
  text-align: center;
  border-radius: 5px;
}
.deleteProduct {
  background: red;
  color: #FFF;
  border: 0px;
  border-radius: 5px;
  padding: 2px 15px;
  font-weight: normal;
  transition: 0.5s;
}
.editProduct {
  background: dodgerblue;
  border: 0px;
  color: #FFF;
  border-radius: 5px;
  padding: 2px 15px;
  font-weight: normal;
  transition: 0.5s;
}
.deleteProduct:hover {
  background: darkred;
  transition: 0.5s;
}
.editProduct:hover {
  background: darkblue;
  transition: 0.5s;
}
</style>