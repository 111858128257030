<template>
  <div id="comments-page">
    <div class="row">
      <div class="col-6"><h1>Список комментариев</h1></div>
      <div class="col-6">
      </div>
      <div class="row">
        <div v-if="statusDelete" class="statusDelete">{{ statusDelete }}</div>
        <table class="table table-striped table-hover" style="font-size: 12px;">
          <thead>
          <tr>
            <th scope="col">Имя пользователя</th>
            <th scope="col">Комментарий</th>
            <th scope="col">Дата и время</th>
            <th scope="col">Удалить</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="comment in comments"  :key="comment._id">
            <td>{{ comment.user_name }}</td>
            <td>{{ comment.comment_body }}</td>
            <td>{{ comment.comment_date }}</td>
            <td><button class="deleteComment" @click="deleteComment(comment._id)">Удалить</button></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";

export default {
  name: "comments_page",
  data() {
    return {
      comments: null,
      searchQuery: null,
      searchQueryName: null,
      statusDelete: null,
      AllowOrigin: 'http://localhost:8080',
      api: 'https://api.partner.su',
    }
  },
  methods: {
    async fetchComments() {
      let myToken = this.$store.state.userInfo.token;

      let axiosConfig = {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': this.AllowOrigin,
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'Authorization' : `Bearer ${myToken}`
        }
      }

      const commentsQuery = await Axios.get(this.api + '/site/comments/all', axiosConfig);
      this.comments = commentsQuery.data.comments_list;

    },
    deleteComment(id) {
      if(confirm("Вы уверены что хотите удалить комментарий?")){

        let myToken = this.$store.state.userInfo.token;

        Axios
            .post(this.api + '/site/comments/delete',
                {
                  'deleteComment': id,
                },
                {
                  withCredentials: true,
                  headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': this.AllowOrigin,
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Authorization' : `Bearer ${myToken}`
                  }
                }

            )
            .then((response) => {
              this.statusDelete = response.data.title;
              this.fetchPages();
            });

      }
    }
  },
  created() {
    this.fetchComments();
  }
}
</script>

<style scoped>
.statusDelete {
  background: red;
  color: #FFFFFF;
  padding: 6px;
  text-align: center;
  border-radius: 5px;
}
.deleteComment {
  background: red;
  color: #FFF;
  border: 0px;
  border-radius: 5px;
  padding: 2px 15px;
  font-weight: normal;
  transition: 0.5s;
}
.deleteComment:hover {
  background: darkred;
  transition: 0.5s;
}
</style>