<template>
  <div id="edit-page">
    <h1>Редактирование страницы сайта</h1>
    <div v-if="statusUpload" class="statusUpload">{{ statusUpload }}</div>
    <div class="row">
      <div class="col-10">
        <input v-model="headerPage" class="title-page" type="text" name="title" placeholder="Заголовок страницы"/><br>
      </div>
      <div class="col-2">
        <button v-on:click="ChangePage()" class="pageButtonUpload">Обновить страницу</button>
      </div>
    </div>
    <div class="row">
      <div class="col-10">
        <input v-model="slugPage" class="slug-page" type="text" name="title" placeholder="URL адрес страницы (slug)"/>
      </div>
      <div class="col-2">
        <select v-model="categoryPage" class="pageCategory" name="categoryPage">
          <option value="Без категории">Без категории</option>
          <option value="Страница блога (без категории)">Страница блога (без категории)</option>
          <option v-for="category in categories" :key="category._id">
            {{ category.headerCategory }}
          </option>
        </select>
        <br>
      </div>
    </div>
    <div class="row">
      <div class="col-8">
        <div class="d-flex justify-content-between">
          <h3>Контент страницы</h3>
          <button class="file-manager-button mb-1" @click="isShowFileManager = true">Добавить медиафайл</button>
          <b-modal modal-class="fullscreen-modal"
                   v-model="isShowFileManager"
                   size="xl"
                   header-class="d-none"
                   body-class="h-100">
            <File_manager
                @close="isShowFileManager = false"
            />
          </b-modal>
        </div>
        <editor v-model="contentPage"
                api-key="3617hroihe3jmdthj5q7e4rq6ujlc3c9avxp9s9haxi5asd9"
                :init="{
             height: 500,
             language: 'ru',
             menubar: true,
             plugins: [
               'image',
               'advlist autolink lists link charmap print preview anchor',
               'searchreplace visualblocks code fullscreen',
               'insertdatetime media table paste code help wordcount'
             ],
             toolbar:
               'undo redo | formatselect | bold italic backcolor | \
               alignleft aligncenter alignright alignjustify | \
               bullist numlist outdent indent | removeformat | help'
           }" placeholer="Контент стараницы"
        />
        <br>
      </div>
      <div class="col-4">

        <b>Изображение анонса:</b><br>
        <div v-if="this.$store.state.Transport.imagesState[0]">
          <img v-bind:src="api + this.$store.state.Transport.imagesState[0]"
               style="width: 100%; border:1px solid #e5e5e5; padding:5px;"/>
        </div>
        <br><br>
        <div class="row">
          <div class="col-3" v-for="image in this.$store.state.Transport.imagesState" :key="image.fileOriginalPath"
               style="width:100px; border:1px solid #e5e5e5; padding:5px; margin:5px;">
            <img v-bind:src="api + image" style="width: 100%;"/>
          </div>
        </div>
        <br><br>
        <button v-if="this.$store.state.Transport.imagesState.length" class="unsetOneProductImage"
                v-on:click="unsetOneProductImage()">Убрать изображение
        </button>
      </div>
    </div>
    <h3>Анонс</h3>
    <textarea v-model="anonsPage" class="anons-page" type="text" name="anons" placeholder="Введите текст анонса..."/>

    <div class="row">
      <div class="col-6">
        <h3>SEO - метаданные</h3>
        <div class="seoInfo">Вы ввели <b>{{ seoTitle.length }}</b> символов. Рекомендуемое кол-во в загаловке <b>60
          символов</b></div>
        <div v-if="seoTitle.length > 60" class="badSeoInfo">Вы превысили рекомендуемое кол-во символов для загаловка!
        </div>
        <input v-model="seoTitle" class="title-seo-page" type="text" name="title-seo"
               placeholder="Заголовок для SEO - title"/>
        <div class="seoInfo">Вы ввели <b>{{ seoDesc.length }}</b> символов. Рекомендуемое кол-во в описании <b>160-180
          символов</b></div>
        <div v-if="seoDesc.length > 180" class="badSeoInfo">Вы превысили рекомендуемое кол-во символов для описания!
        </div>
        <textarea v-model="seoDesc" class="desc-seo-page" type="text" name="desc-seo"
                  placeholder="Описание страницы для SEO - description"/>
        <input v-model="seoKeywords" class="key-seo-page" type="text" name="kewords-seo"
               placeholder="Ключевые слова для SEO - keywords"/>
      </div>
      <div class="col-6">
        <h3>Как будет выглядеть на поиске</h3>
        <div class="seo-example-gen">
          <div class="seo-title-how">{{ seoTitle }}</div>
          <div class="seo-desc-how">{{ seoDesc }}</div>
          <div class="seo-slug-how">https://partner.su/{{ slugPage }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Editor from '@tinymce/tinymce-vue'
import Axios from "axios";
import File_manager from "./file_manager.vue";

export default {
  name: "edit_page",
  props: ['idPage'],
  data() {
    return {
      headerPage: null,
      slugPage: null,
      categoryPage: "Без категории",
      categories: null,
      contentPage: null,
      anonsPage: null,
      seoTitle: "Title - Заголовок",
      seoDesc: "Description - Описание",
      seoKeywords: "Ключевые слова",
      searchQuery: null,
      searchQueryName: null,
      statusUpload: null,
      pageData: null,
      anonsImage: null,
      AllowOrigin: 'http://localhost:8080',
      api: 'https://site-api.partner.su',
      isShowFileManager: false
    }
  },
  components: {
    File_manager,
    'editor': Editor
  },
  methods: {
    async fetchPage(id) {
      let myToken = this.$store.state.userInfo.token;

      let axiosConfig = {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': this.AllowOrigin,
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'Authorization': `Bearer ${myToken}`
        }
      }

      Axios
          .post(this.api + '/site/one-page/',
              {
                idPage: id
              },
              axiosConfig
          )
          .then((response) => {
            this.idPage = response.data.pages_list[0]._id;
            this.headerPage = response.data.pages_list[0].headerPage;
            this.slugPage = response.data.pages_list[0].slugPage;
            this.categoryPage = response.data.pages_list[0].categoryPage;
            this.contentPage = response.data.pages_list[0].contentPage;
            this.anonsImage = response.data.pages_list[0].anonsImage;
            this.anonsPage = response.data.pages_list[0].anonsPage;
            if (response.data.pages_list[0].seoTitle) {
              this.seoTitle = response.data.pages_list[0].seoTitle;
            }
            if (response.data.pages_list[0].seoDesc) {
              this.seoDesc = response.data.pages_list[0].seoDesc;
            }
            if (response.data.pages_list[0].seoKeywords) {
              this.seoKeywords = response.data.pages_list[0].seoKeywords;
            }
            this.unsetProductImages();
            // Передача картинок в State
            for (let i = 0; i < this.anonsImage.length; i++) {
              this.$store.dispatch('add_image_to_product', this.anonsImage[i]);
            }

          })
          .catch(error => {
            console.log(error);
            this.errored = true;
          })
          .finally(() => (this.loading = false));
    },
    async ChangePage() {

      let myToken = this.$store.state.userInfo.token;

      Axios
          .post(this.api + '/site/edit-page/',
              {
                'editPage': this.idPage,
                'headerPage': this.headerPage,
                'categoryPage': this.categoryPage,
                'slugPage': this.slugPage,
                'contentPage': this.contentPage,
                'anonsImage': this.$store.state.Transport.imagesState,
                'anonsPage': this.anonsPage,
                'seoTitle': this.seoTitle,
                'seoDesc': this.seoDesc,
                'seoKeywords': this.seoKeywords,
                'userUpdate': this.$store.state.userInfo.userInfo.origname
              },
              {
                withCredentials: true,
                headers: {
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin': this.AllowOrigin,
                  'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                  'Authorization': `Bearer ${myToken}`
                }
              }
          )
          .then((response) => {
            this.statusUpload = response.data.title;
            this.fetchPage(this.idPage);
          });
    },
    fetchCategoryPages() {
      let myToken = this.$store.state.userInfo.token;

      let axiosConfig = {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': this.AllowOrigin,
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'Authorization': `Bearer ${myToken}`
        }
      }

      Axios
          .get(this.api + '/site/all-categories/', axiosConfig)
          .then((response) => {
            this.categories = response.data.categories_list;
          })
          .catch(error => {
            console.log(error);
            this.errored = true;
          })
          .finally(() => (this.loading = false));
    },
    // Удалить последнее добавленное изображение
    unsetOneProductImage() {

      this.$store.dispatch('unset_one_image')

    },
    // Удалить изображения (все)
    unsetProductImages() {

      this.$store.dispatch('unset_images')

    },
  },
  created() {
    this.fetchPage(this.idPage);
    this.fetchCategoryPages();
    if (this.$route.params.statusUpload) {
      this.statusUpload = this.$route.params.statusUpload
    }
  }
}
</script>

<style scoped>
.statusUpload {
  background: green;
  color: #FFFFFF;
  padding: 6px;
  text-align: center;
  border-radius: 5px;
}

.block-json-content {
  background: #666;
  width: 100%;
  color: #ffc107;
  padding: 50px;
  border: 1px solid #111111;
}

.pageButtonUpload {
  width: 100%;
  border: 0px;
  background: green;
  color: #FFF;
  border-radius: 10px;
  padding: 8px;
  margin-top: 30px;
}

.pageCategory {
  width: 100%;
  padding: 10px;
  margin-top: 32px;
  border: 1px solid #e5e5e5;
  border-radius: 0px;
}

.title-page {
  width: 100%;
  padding: 20px 8px;
  border-radius: 0px;
  border: 1px solid #e5e5e5;
  margin: 20px 0px;
}

.slug-page {
  width: 100%;
  background: #F2F2F2;
  padding: 20px 8px;
  border-radius: 0px;
  border: 1px solid #e5e5e5;
  margin: 20px 0px;
}

.anons-page {
  width: 100%;
  height: 100px;
  padding: 20px 8px;
  border-radius: 0px;
  border: 1px solid #e5e5e5;
  margin: 20px 0px;
}

.title-seo-page {
  width: 100%;
  padding: 20px 8px;
  border-radius: 0px;
  border: 1px solid #e5e5e5;
  margin: 20px 0px;
}

.desc-seo-page {
  width: 100%;
  height: 100px;
  padding: 20px 8px;
  border-radius: 0px;
  border: 1px solid #e5e5e5;
  margin: 20px 0px;
}

.key-seo-page {
  width: 100%;
  padding: 20px 8px;
  border-radius: 0px;
  border: 1px solid #e5e5e5;
  margin: 20px 0px;
}

.seo-example-gen {
  width: 100%;
  height: 150px;
  border-radius: 15px;
  border: 1px solid #e5e5e5;
  padding: 20px;
}

.seo-title-how {
  font-size: 16px;
  font-weight: bold;
  color: #0d6efd;
}

.seo-desc-how {
  font-size: 14px;
  color: #111111;
}

.seo-slug-how {
  font-size: 12px;
  color: #CCCCCC;
}

.statusUpload {
  background: green;
  color: #FFFFFF;
  padding: 6px;
  text-align: center;
  border-radius: 5px;
}

.seoInfo {
  font-size: 12px;
  color: #666;
}

.badSeoInfo {
  color: red;
  font-size: 12px;
  font-weight: bold;
}

.unsetOneProductImage {
  width: 175px;
  border: 0px;
  background: darkviolet;
  color: #FFF;
  border-radius: 10px;
  padding: 8px;
  margin-top: 30px;
}

.unsetOneProductImage:hover {
  background-color: #666666;
}
</style>