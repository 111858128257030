// Transport for FileManager images and other

export default {

    state: {

        imagesState: [],
        imagesState2: [],

    },
    mutations: {

        // Add product Images
        ADD_PRODUCT_IMAGES (state, Data) {
           state.imagesState.push(Data);
        },
        ADD_PRODUCT_IMAGES2 (state, Data) {
           state.imagesState2.push(Data);
        },
        UNSET_ONE_PRODUCT_IMAGES (state){
            state.imagesState.splice(-1,1);
        },
        UNSET_ONE_PRODUCT_IMAGES2 (state){
            state.imagesState2.splice(-1,1);
        },
        UNSET_PRODUCT_IMAGES (state){
           state.imagesState = [];
        },
        UNSET_PRODUCT_IMAGES2 (state){
           state.imagesState2 = [];
        },

    },
    actions: {

        add_image_to_product({ commit }, payload) {
            commit("ADD_PRODUCT_IMAGES", payload);
        },
        add_image_to_product2({ commit }, payload) {
            commit("ADD_PRODUCT_IMAGES2", payload);
        },
        unset_one_image({ commit }) {
            commit("UNSET_ONE_PRODUCT_IMAGES");
        },
        unset_one_image2({ commit }) {
            commit("UNSET_ONE_PRODUCT_IMAGES2");
        },
        unset_images({ commit }) {
            commit("UNSET_PRODUCT_IMAGES");
        },
        unset_images2({ commit }) {
            commit("UNSET_PRODUCT_IMAGES2");
        },

    },
    getters: {

    }

}