import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";
import Auth from './modules/auth';
import AuthPageApi from './modules/authp';
import Transport from "./modules/transport";

Vue.use(Vuex);

export const store = new Vuex.Store({
   modules: {
       Auth,
       AuthPageApi,
       Transport
   },
   plugins: [createPersistedState()],
});