<template>
  <div id="category-pages">
    <div class="row">
      <div class="col-6"><h1>Категории страниц сайта</h1></div>
      <div class="col-6">
        <p style="text-align: right; padding: 10px;">
          <button class="createCategoryButton">
            <router-link to="/create-category-pages">Создать категорию</router-link>
          </button>
        </p>
      </div>
      <div class="row">
        <div v-if="statusDelete" class="statusDelete">{{ statusDelete }}</div>
        <table class="table table-striped table-hover" style="font-size: 12px;">
          <thead>
          <tr>
            <th scope="col">Категория</th>
            <th scope="col">URL</th>
            <th scope="col">Изменить</th>
            <th scope="col">Удалить</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="category in categories"  :key="category._id">
            <td>{{ category.headerCategory }}</td>
            <td>{{ category.slugCategory }}</td>
            <td><router-link :to="{name: 'edit_category_pages', params: { idCategoryPages: category._id } }"><button class="editCategory">Изменить</button></router-link></td>
            <td><button class="deleteCategory" @click="deleteCategory(category._id)">Удалить</button></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>




</template>

<script>
import Axios from "axios";

export default {
  name: "category_pages",
  data() {
    return {
      categories: null,
      searchQuery: null,
      searchQueryName: null,
      statusDelete: null,
      AllowOrigin: 'http://localhost:8080',
      api: 'https://site-api.partner.su',
    }
  },
  methods: {
    async fetchCategoryPages() {
      let myToken = this.$store.state.userInfo.token;

      let axiosConfig = {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': this.AllowOrigin,
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'Authorization' : `Bearer ${myToken}`
        }
      }

      Axios
          .get(this.api + '/site/all-categories/', axiosConfig)
          .then((response) => {
            this.categories  = response.data.categories_list;
          })
          .catch(error => {
            console.log(error);
            this.errored = true;
          })
          .finally(() => (this.loading = false));
    },
    deleteCategory(id) {
      if(confirm("Вы уверены что хотите удалить категорию?")){

        let myToken = this.$store.state.userInfo.token;

        Axios
            .post(this.api + '/site/delete-category-pages/',
                {
                  'deleteCategory': id,
                },
                {
                  withCredentials: true,
                  headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': this.AllowOrigin,
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Authorization' : `Bearer ${myToken}`
                  }
                }

            )
            .then((response) => {
              this.statusDelete = response.data.answer;
              this.fetchCategoryPages();
            });

      }
    }
  },
  created() {
    this.fetchCategoryPages();
  }
}
</script>

<style scoped>
.createCategoryButton {
  padding: 8px;
  border:0px;
  border-radius: 10px;
  color: #ffffff;
  background: green;
}
.createCategoryButton a {
  color: #ffffff;
  text-decoration: none;
}
.createCategoryButton:hover {
  background: darkgreen;
}
.statusDelete {
  background: red;
  color: #FFFFFF;
  padding: 6px;
  text-align: center;
  border-radius: 5px;
}
.deleteCategory {
  background: red;
  color: #FFF;
  border: 0px;
  border-radius: 5px;
  padding: 2px 15px;
  font-weight: normal;
  transition: 0.5s;
}
.editCategory {
  background: dodgerblue;
  border: 0px;
  color: #FFF;
  border-radius: 5px;
  padding: 2px 15px;
  font-weight: normal;
  transition: 0.5s;
}
.deleteCategory:hover {
  background: darkred;
  transition: 0.5s;
}
.editCategory:hover {
  background: darkblue;
  transition: 0.5s;
}
</style>