<template>
  <div id="edit-order">
    <h1>Заказ № {{ numberOrder }}, дата заказа: {{ orderDate }}</h1>
    <!-- <div v-if="statusUpload" class="statusUpload">{{ statusUpload }}</div> -->
    <div class="row">

      <div class="col-sm-6">
        <h3>Физическое лицо</h3>
        <h3>Ф.И.О:</h3>
        <input v-model="full_order.fl_fio" class="input-order" type="text" placeholder="" readonly />
        <h3>E-mail</h3>
        <input v-model="full_order.fl_mail" class="input-order" type="text" placeholder="" readonly />
        <h3>Телефон</h3>
        <input v-model="full_order.fl_phone" class="input-order" type="text" placeholder="" readonly />
        <h3>Город</h3>
        <input v-model="full_order.fl_city" class="input-order" type="text" placeholder="" readonly />

      </div>
      <div class="col-sm-6">
        <h3>Юридическое лицо</h3>
        <h3>Компания</h3>
        <input v-model="full_order.ul_name" class="input-order" type="text" placeholder="" readonly />
        <h3>ИНН</h3>
        <input v-model="full_order.ul_inn" class="input-order" type="text" placeholder="" readonly />
        <h3>КПП</h3>
        <input v-model="full_order.ul_kpp" class="input-order" type="text" placeholder="" readonly />
        <h3>Юр. адрес</h3>
        <input v-model="full_order.ul_address" class="input-order" type="text" placeholder="" readonly />
        <h3>Телефон</h3>
        <input v-model="full_order.ul_phone" class="input-order" type="text" placeholder="" readonly />
        <h3>E-mail</h3>
        <input v-model="full_order.ul_mail" class="input-order" type="text" placeholder="" readonly />
        <h3>Контактное лицо</h3>
        <input v-model="full_order.ul_contact" class="input-order" type="text" placeholder="" readonly />

      </div>

    </div>


    <div class="row">
      <div class="col-12">
        <h3>Комментарий клиента:</h3>
        <textarea v-model="full_order.order_comment" class="input-order" type="text" placeholder="Комментарий клиента" readonly/>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h3>Продукция в заказе:</h3>
        <table class="table table-striped table-hover" style="font-size: 12px;">
          <thead>
          <tr>
            <th scope="col">Картинка</th>
            <th scope="col">Наименование</th>
            <th scope="col">Стоимость</th>
            <th scope="col">Кол-во</th>
            <th scope="col">Общая стоимость шт.</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="product in productsOrder"  :key="product._id">
            <td><img style="height:100px;" v-bind:src="'https://exchange.partner.su/img/' + product.img_path" /></td>
            <td>{{ product.name }}</td>
            <td>{{ product.product_price }}р.</td>
            <td>{{ product.quantity }}</td>
            <td>{{ product.sum }}р. </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="col-12">
        <h3>Итоговая стоимость заказа: {{ fullPrice }}р.</h3>
        <br>
        <br>
      <div class="row">
        <div class="col-6">
          <h2>Информация о доставке</h2>
          <h3>Тип доставки:</h3>
          <input v-model="full_order.delivery_type" class="input-order" type="text" placeholder="" readonly/>
          <h3>Пункт выдачи:</h3>
          <input v-model="full_order.delivery_pvz" class="input-order" type="text" placeholder="" readonly/>
          <h3>Стоимость доставки:</h3>
          <input v-model="full_order.delivery_price" class="input-order" type="text" placeholder="" readonly/>
        </div>
        <div class="col-6">
          <h2>Информация о оплате</h2>
          <h3>Тип оплаты:</h3>
          <input v-model="full_order.pay_type" class="input-order" type="text" placeholder="" readonly/>
          <h3>Состояние оплаты:</h3>
          <input v-model="robokassa" class="input-order" type="text" placeholder="ROBOKASSA" readonly/>
          <h3>Полная стоимость заказа:</h3>
          <input v-model="full_order.all_price" class="input-order" type="text" placeholder="" readonly/>
        </div>
      </div>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
      </div>
    </div>

  </div>
</template>

<script>
import Axios from "axios";

export default {
  name: "edit_order",
  props: ['idOrder'],
  data() {
    return {
      numberOrder: null,
      fioOrder: null,
      ulNameOrder: null,
      innOrder: null,
      phoneOrder: null,
      adOrder: null,
      messageOrder: null,
      deliveryDateOrder: null,
      dateOrder: null,
      fullPrice: null,
      productsOrder:null,
      productsInOrder: null,
      full_order:[],
      robokassa: "---- req robokassa ---",
      orderDate: "",
    }
  },
  methods: {
    fetchOneOrder(id) {
      let myToken = this.$store.state.userInfo.token;

      let axiosConfig = {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': 'http://localhost:8080/',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'Authorization' : `Bearer ${myToken}`
        }
      }

      Axios
          .post('https://api.partner.su/shop/orders/one-order/',
              {
                idOrder: id
              },
              axiosConfig
          )
          .then((response) => {
            // TODO ПОМЕНЯТЬ ДАННЫЕ
            this.full_order = response.data.order_list[0];
            this.numberOrder = response.data.order_list[0].number;
            this.dateOrder = response.data.order_list[0].createdAt;
            this.fioOrder = response.data.order_list[0].fio;
            this.phoneOrder = response.data.order_list[0].phone;
            this.adOrder = response.data.order_list[0].ad;
            this.ulNameOrder = response.data.order_list[0].ulName;
            this.innOrder = response.data.order_list[0].inn;
            this.messageOrder = response.data.order_list[0].message;
            this.deliveryDateOrder = response.data.order_list[0].date;

            this.productsOrder = response.data.order_list[0].products;

            this.fullPrice = response.data.order_list[0].order_price;

            this.orderDate = new Date(response.data.order_list[0].createdAt).toLocaleDateString()
          })
          .catch(error => {
            console.log(error);
            this.errored = true;
          })
          .finally(() => (this.loading = false));
    },
    /*
    ChangeOrder() {
      let myToken = this.$store.state.userInfo.token;

      Axios
          .post('http://localhost:3000/orders/edit-order/',
              {
                // TODO ПОМЕНЯТЬ ДАННЫЕ
                'editPage': this.idPage,
                'headerPage': this.headerPage,
                'categoryPage': this.categoryPage,
                'slugPage': this.slugPage,
                'contentPage': this.contentPage,
                'anonsPage': this.anonsPage,
                'seoTitle': this.seoTitle,
                'seoDesc':this.seoDesc,
                'seoKeywords': this.seoKeywords,
                'userUpdate': this.$store.state.userInfo.userInfo.origname
              },
              {
                withCredentials: true,
                headers: {
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin': 'http://localhost:3000/',
                  'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                  'Authorization' : `Bearer ${myToken}`
                }
              }

          )
          .then((response) => {
            this.statusUpload = response.data.title;
            this.fetchOneOrder(this.idOrder);
          });
    },
    */

  },
  created() {
    this.fetchOneOrder(this.idOrder);
  }
}
</script>

<style scoped>
.statusUpload {
  background: green;
  color: #FFFFFF;
  padding: 6px;
  text-align: center;
  border-radius: 5px;
}
#edit-order h3 {
  font-size: 14px;
  font-weight: bold;
}
.block-json-content {
  background:#666;
  width:100%;
  color:#ffc107;
  padding:50px;
  border:1px solid #111111;
}
.orderButtonUpload {
  width: 100%;
  border: 0px;
  background: green;
  color: #FFF;
  border-radius: 10px;
  padding: 8px;
  margin-top: 30px;
}
.input-order {
  width: 100%;
  padding: 10px 8px;
  border-radius: 0px;
  border:1px solid #e5e5e5;
  background-color: #F2F2F2;
  margin: 20px 0px;
}
.statusUpload {
  background: green;
  color: #FFFFFF;
  padding: 6px;
  text-align: center;
  border-radius: 5px;
}
</style>