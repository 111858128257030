<template>
  <div id="edit-category-pages">
    <h1>Редактирование категории</h1>
    <div v-if="statusUpload" class="statusUpload">{{ statusUpload }}</div>
    <div class="row">
      <div class="col-10">
        <input v-model="headerCategory" class="title-page" type="text" name="title" placeholder="Имя категории"/>
      </div>
      <div class="col-2">
        <button v-on:click="EditCategory()" class="categoryButtonUpload">Изменить категорию</button>
      </div>
    </div>
    <div class="row">
      <div class="col-10">
        <input v-model="slugCategory" class="slug-category" type="text" name="title" placeholder="URL адрес категории (slug)"/>
      </div>
      <div class="col-2">
        <select v-model="parentCategory" class="subCategory">
          <option v-if="this.parentCategory === null || this.parentCategory === 'null'" value="Верхний уровень">Верхний уровень</option>
          <option v-else v-bind:value="this.parentCategory">{{ this.parentCategory }}</option>
          <!-- Здесь перебор под-категорий !!! -->
        </select>
        <br>
      </div>
    </div>
    <h3>Контент категории</h3>
    <editor v-model="contentCategory"
            api-key="3617hroihe3jmdthj5q7e4rq6ujlc3c9avxp9s9haxi5asd9"
            :init="{
         height: 500,
         language: 'ru',
         menubar: true,
         plugins: [
           'advlist autolink lists link images charmap print preview anchor',
           'searchreplace visualblocks code fullscreen',
           'insertdatetime media table paste code help wordcount'
         ],
         toolbar:
           'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help'
       }"
            placeholer="Контент категории"
    /><br>

    <div class="row">
      <div class="col-6">
        <h3>SEO - метаданные</h3>
        <input v-model="seoTitle" class="title-seo-page" type="text" name="title-seo" placeholder="Заголовок для SEO - title"/>
        <textarea v-model="seoDesc" class="desc-seo-page" type="text" name="desc-seo" placeholder="Описание страницы для SEO - description"/>
        <input v-model="seoKeywords" class="key-seo-page" type="text" name="kewords-seo" placeholder="Ключевые слова для SEO - keywords"/>
      </div>
      <div class="col-6">
        <h3>Как будет выглядеть на поиске</h3>
        <div class="seo-example-gen">
          <div class="seo-title-how">{{ seoTitle }}</div>
          <div class="seo-desc-how">{{ seoDesc }}</div>
          <div class="seo-slug-how">/{{ slugCategory }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import Axios from "axios";

export default {
  name: "edit_category_pages",
  props: ['idCategoryPages'],
  data() {
    return {
      idCat: null,
      headerCategory: null,
      slugCategory: null,
      subCategoryDefault:null, //1v
      subCategory: null,       //2v
      parentCategory: null,    //3v
      contentCategory: null,
      seoTitle: null,
      seoDesc: null,
      seoKeywords: null,
      searchQuery: null,
      searchQueryName: null,
      statusUpload:null,
      AllowOrigin: 'http://localhost:8080',
      api: 'https://site-api.partner.su',
    }
  },
  components: {
    'editor': Editor
  },
  methods: {
    async fetchCategory(id) {
      let myToken = this.$store.state.userInfo.token;

      let axiosConfig = {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': this.AllowOrigin,
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'Authorization' : `Bearer ${myToken}`
        }
      }

      Axios
          .post(this.api + '/site/one-category/',
              {
                idCategory: id
              },
              axiosConfig
          )
          .then((response) => {
            this.idCat = response.data.category_pages_list[0]._id;
            this.headerCategory = response.data.category_pages_list[0].headerCategory;
            this.slugCategory = response.data.category_pages_list[0].slugCategory;
            this.contentCategory = response.data.category_pages_list[0].contentCategory;
            this.seoTitle = response.data.category_pages_list[0].seoTitle;
            this.seoDesc = response.data.category_pages_list[0].seoDesc;
            this.seoKeywords = response.data.category_pages_list[0].seoKeywords;

            if(response.data.category_pages_list[0].parentCategory) {
              this.parentCategory = response.data.category_pages_list[0].parentCategory;
            } else {
              this.parentCategory = null;
            }
          })
          .catch(error => {
            console.log(error);
            this.errored = true;
          })
          .finally(() => (this.loading = false));
    },
    async EditCategory() {

      let myToken = this.$store.state.userInfo.token;

      Axios
          .post(this.api + '/site/edit-category-pages/',
              {
                'editCategory': this.idCategoryPages,
                'headerCategory': this.headerCategory,
                'slugCategory': this.slugCategory,
                'parentCategory': this.subCategory, // With subcategory DATA
                'contentCategory': this.contentCategory,
                'seoTitle': this.seoTitle,
                'seoDesc':this.seoDesc,
                'seoKeywords': this.seoKeywords,
                'userUpdate': this.$store.state.userInfo.userInfo.origname
              },
              {
                withCredentials: true,
                headers: {
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin': this.AllowOrigin,
                  'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                  'Authorization' : `Bearer ${myToken}`
                }
              }

          )
          .then((response) => {
            this.statusUpload = response.data.answer;
          });
    }
  },
  created() {
    this.fetchCategory(this.idCategoryPages);
  }
}
</script>

<style scoped>
.statusUpload {
  background: green;
  color: #FFFFFF;
  padding: 6px;
  text-align: center;
  border-radius: 5px;
}
.categoryButtonUpload {
  width: 100%;
  border: 0px;
  background: green;
  color: #FFF;
  border-radius: 10px;
  padding: 8px;
  margin-top: 30px;
}
.subCategory {
  width: 100%;
  padding: 10px;
  margin-top: 32px;
  border: 1px solid #e5e5e5;
  border-radius: 0px;
}
.title-page {
  width: 100%;
  padding: 20px 8px;
  border-radius: 0px;
  border:1px solid #e5e5e5;
  margin: 20px 0px;
}
.slug-category {
  width: 100%;
  background: #F2F2F2;
  padding: 20px 8px;
  border-radius: 0px;
  border:1px solid #e5e5e5;
  margin: 20px 0px;
}
.title-seo-page {
  width: 100%;
  padding: 20px 8px;
  border-radius: 0px;
  border:1px solid #e5e5e5;
  margin: 20px 0px;
}
.desc-seo-page {
  width: 100%;
  height: 100px;
  padding: 20px 8px;
  border-radius: 0px;
  border:1px solid #e5e5e5;
  margin: 20px 0px;
}
.key-seo-page {
  width: 100%;
  padding: 20px 8px;
  border-radius: 0px;
  border:1px solid #e5e5e5;
  margin: 20px 0px;
}
.seo-example-gen {
  width:100%;
  height: 150px;
  border-radius: 15px;
  border:1px solid #e5e5e5;
  padding: 20px;
}
.seo-title-how {
  font-size: 16px;
  font-weight: bold;
  color:#0d6efd;
}
.seo-desc-how {
  font-size: 14px;
  color: #111111;
}
.seo-slug-how {
  font-size: 12px;
  color:#CCCCCC;
}
</style>