<template>
  <div id="edit_shop_category">
    <h1>Редактирование описания категории из 1С</h1>

    <div v-if="statusUpload" class="statusUpload">{{ statusUpload }}</div>
    <div class="row">
      <div class="col-10">
        <input v-model="nameShopCategory" class="title-page" type="text" name="title" placeholder="Имя категории"
               readonly/>
      </div>
      <div class="col-2">
        <button v-on:click="EditShopCategory()" class="categoryButtonUpload">Изменить категорию</button>
      </div>
    </div>
    <div class="row">

      <div class="col-12">
        <input v-model="slugShopCategory" class="slug-category" type="text" name="title"
               placeholder="URL адрес категории (slug)" readonly/>
      </div>

    </div>
    <div class="row">
      <div class="col-8">
        <div class="d-flex justify-content-between">
          <h3>Описание категории</h3>
          <button class="file-manager-button mb-1" @click="isShowFileManager1 = true">Добавить медиафайл</button>
          <b-modal modal-class="fullscreen-modal"
                   v-model="isShowFileManager1"
                   size="xl"
                   header-class="d-none"
                   body-class="h-100">
            <File_manager
                @close="isShowFileManager1 = false"
            />
          </b-modal>
        </div>
        <editor v-model="contentShopCategoryDesc"
                api-key="3617hroihe3jmdthj5q7e4rq6ujlc3c9avxp9s9haxi5asd9"
                :init="{
             height: 500,
             language: 'ru',
             menubar: true,
             plugins: [
               'image',
               'advlist autolink lists link charmap print preview anchor',
               'searchreplace visualblocks code fullscreen',
               'insertdatetime media table paste code help wordcount'
             ],
             toolbar:
               'undo redo | formatselect | bold italic backcolor | \
               alignleft aligncenter alignright alignjustify | \
               bullist numlist outdent indent | removeformat | help'
           }" placeholer="Контент стараницы"
        />
      </div>
      <div class="col-4">
        <b>Изображение анонса:</b><br>
        <div v-if="this.$store.state.Transport.imagesState[0]">
          <img v-bind:src="api + this.$store.state.Transport.imagesState[0]"
               style="width: 100%; border:1px solid #e5e5e5; padding:5px;"/>
        </div>
        <br><br>
        <div class="row">
          <div class="col-3" v-for="image in this.$store.state.Transport.imagesState" :key="image.fileOriginalPath"
               style="width:100px; border:1px solid #e5e5e5; padding:5px; margin:5px;">
            <img v-bind:src="api + image" style="width: 100%;"/>
          </div>
        </div>
        <br><br>
        <button v-if="this.$store.state.Transport.imagesState.length" class="unsetOneProductImage"
                v-on:click="unsetOneProductImage()">Убрать изображение
        </button>
      </div>
    </div>

    <br>
    <div class="row">
      <div class="col-8">
        <div class="d-flex justify-content-between">
          <h3>Контент категории</h3>
          <button class="file-manager-button mb-1" @click="isShowFileManager1 = true">Добавить медиафайл</button>
          <b-modal modal-class="fullscreen-modal"
                   v-model="isShowFileManager1"
                   size="xl"
                   header-class="d-none"
                   body-class="h-100">
            <File_manager
                @close="isShowFileManager1 = false"
            />
          </b-modal>
        </div>
        <editor v-model="contentShopCategory"
                api-key="3617hroihe3jmdthj5q7e4rq6ujlc3c9avxp9s9haxi5asd9"
                :init="{
         height: 500,
         language: 'ru',
         menubar: true,
         plugins: [
           'advlist autolink lists link images charmap print preview anchor',
           'searchreplace visualblocks code fullscreen',
           'insertdatetime media table paste code help wordcount'
         ],
         toolbar:
           'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help'
       }"
                placeholer="Контент категории"
        />
        <br>
      </div>
      <div class="col-4">
        <b>Изображение анонса:</b><br>
        <div v-if="this.$store.state.Transport.imagesState2[0]">
          <img v-bind:src="api + this.$store.state.Transport.imagesState2[0]"
               style="width: 100%; border:1px solid #e5e5e5; padding:5px;"/>
        </div>
        <br><br>
        <div class="row">
          <div class="col-3" v-for="image in this.$store.state.Transport.imagesState2" :key="image.fileOriginalPath"
               style="width:100px; border:1px solid #e5e5e5; padding:5px; margin:5px;">
            <img v-bind:src="api + image" style="width: 100%;"/>
          </div>
        </div>
        <br><br>
        <button v-if="this.$store.state.Transport.imagesState2.length" class="unsetOneProductImage"
                v-on:click="unsetOneProductImage2()">Убрать изображение
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <h3>SEO - метаданные</h3>
        <div class="seoInfo">Вы ввели <b>{{ seoTitle.length }}</b> символов. Рекомендуемое кол-во в загаловке <b>60
          символов</b></div>
        <div v-if="seoTitle.length > 60" class="badSeoInfo">Вы превысили рекомендуемое кол-во символов для загаловка!
        </div>
        <input v-model="seoTitle" class="title-seo-page" type="text" name="title-seo"
               placeholder="Заголовок для SEO - title"/>
        <div class="seoInfo">Вы ввели <b>{{ seoDesc.length }}</b> символов. Рекомендуемое кол-во в описании <b>160-180
          символов</b></div>
        <div v-if="seoDesc.length > 180" class="badSeoInfo">Вы превысили рекомендуемое кол-во символов для описания!
        </div>
        <textarea v-model="seoDesc" class="desc-seo-page" type="text" name="desc-seo"
                  placeholder="Описание страницы для SEO - description"/>
        <input v-model="seoKeywords" class="key-seo-page" type="text" name="kewords-seo"
               placeholder="Ключевые слова для SEO - keywords"/>
      </div>
      <div class="col-6">
        <h3>Как будет выглядеть на поиске</h3>
        <div class="seo-example-gen">
          <div class="seo-title-how">{{ seoTitle }}</div>
          <div class="seo-desc-how">{{ seoDesc }}</div>
          <div class="seo-slug-how">https://partner.su/{{ slugShopCategory }}</div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import Editor from '@tinymce/tinymce-vue';
import Axios from 'axios';
import File_manager from '@/components/file_manager.vue';

export default {
  name: 'edit_shop_category',
  props: ['idShopCategory'],
  data() {
    return {
      fidShopCat: null,
      idContentCategory: null,
      nameShopCategory: null,
      slugShopCategory: null,
      contentShopCategoryStatus: '0',
      contentShopCategory: null,
      contentShopCategoryDesc: null,
      statusUpload: null,
      category_json: null,
      seoTitle: 'Title - Заголовок',
      seoDesc: 'Description - Описание',
      seoKeywords: 'Ключевые слова',
      isShowFileManager1: false,
      isShowFileManager2: false,
      api: 'https://site-api.partner.su',
      anonsImageDesc: null,
      anonsImageContent: null,
    };
  },
  components: {
    File_manager,
    'editor': Editor,
  },
  methods: {
    unsetOneProductImage() {
      this.$store.dispatch('unset_one_image');
    },
    unsetProductImages() {
      this.$store.dispatch('unset_images');
    },
    unsetProductImages2() {
      this.$store.dispatch('unset_images2');
    },
    unsetOneProductImage2() {

      this.$store.dispatch('unset_one_image2');

    },
    async fetchShopCategory(id) {
      let myToken = this.$store.state.userInfo.token;

      let axiosConfig = {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': 'https://api.partner.su/',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'Authorization': `Bearer ${myToken}`,
        },
      };

      const queryCat = await Axios.post(
          'https://api.partner.su/shop/one-category/',
          { idShopCategory: id }, axiosConfig,
      );

      this.fidShopCat = queryCat.data.category_shop_list[0].f_id;
      this.nameShopCategory = queryCat.data.category_shop_list[0].name;
      this.slugShopCategory = queryCat.data.category_shop_list[0].slug;
      this.category_json = queryCat.data;

      const queryCatInfo = await Axios.post(
          'https://api.partner.su/shop/one-category-info/',
          { fidShopCategory: this.fidShopCat },
          axiosConfig,
      );

      console.log('response: ' + JSON.stringify(queryCatInfo.data));

      this.unsetProductImages();
      this.unsetProductImages2();
      if (queryCatInfo.data.info_category_shop_list.length > 0) {
        this.contentShopCategory = queryCatInfo.data.info_category_shop_list[0].content;
        this.contentShopCategoryDesc = queryCatInfo.data.info_category_shop_list[0].contentDesc;
        this.anonsImageDesc = queryCatInfo.data.info_category_shop_list[0].anonsImageDesc;
        this.anonsImageContent = queryCatInfo.data.info_category_shop_list[0].anonsImageContent;
        if (this.anonsImageDesc) {
          for (let i = 0; i < this.anonsImageDesc.length; i++) {
            this.$store.dispatch('add_image_to_product', this.anonsImageDesc[i]);
          }
        }
        if (this.anonsImageContent) {
          for (let i = 0; i < this.anonsImageContent.length; i++) {
            this.$store.dispatch('add_image_to_product2', this.anonsImageContent[i]);
          }
        }
        if (queryCatInfo.data.info_category_shop_list[0].seoTitle) {
          this.seoTitle = queryCatInfo.data.info_category_shop_list[0].seoTitle;
        }
        if (queryCatInfo.data.info_category_shop_list[0].seoDesc) {
          this.seoDesc = queryCatInfo.data.info_category_shop_list[0].seoDesc;
        }
        if (queryCatInfo.data.info_category_shop_list[0].seoKeywords) {
          this.seoKeywords = queryCatInfo.data.info_category_shop_list[0].seoKeywords;
        }
      } else {
        this.contentShopCategoryStatus = '1';
      }

    },
    async EditShopCategory() {

      let myToken = this.$store.state.userInfo.token;

      let axiosConfig = {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': 'https://api.partner.su/',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'Authorization': `Bearer ${myToken}`,
        },
      };

      if (this.contentShopCategoryStatus === '1') {

        const queryAddInfo = await Axios.post(
            'https://api.partner.su/shop/add/category-info',
            {
              'fidShopCategory': this.fidShopCat,
              'nameShopCategory': this.nameShopCategory,
              'contentShopCategory': this.contentShopCategory,
              'contentShopCategoryDesc': this.contentShopCategoryDesc,
              'seoTitle': this.seoTitle,
              'seoDesc': this.seoDesc,
              'seoKeywords': this.seoKeywords,
              'anonsImageDesc': this.$store.state.Transport.imagesState,
              'anonsImageContent': this.$store.state.Transport.imagesState2,
            }, axiosConfig);

        this.statusUpload = queryAddInfo.data.answer;

      } else {

        const queryEditInfo = await Axios.post(
            'https://api.partner.su/shop/edit/category-info',
            {
              'fidShopCategory': this.fidShopCat,
              'nameShopCategory': this.nameShopCategory,
              'contentShopCategory': this.contentShopCategory,
              'contentShopCategoryDesc': this.contentShopCategoryDesc,
              'seoTitle': this.seoTitle,
              'seoDesc': this.seoDesc,
              'seoKeywords': this.seoKeywords,
              'anonsImageDesc': this.$store.state.Transport.imagesState,
              'anonsImageContent': this.$store.state.Transport.imagesState2,
            }, axiosConfig);

        this.statusUpload = queryEditInfo.data.answer;

      }


    },
  },
  created() {
    this.fetchShopCategory(this.idShopCategory);
  },
};
</script>

<style scoped>
.statusUpload {
  background: green;
  color: #FFFFFF;
  padding: 6px;
  text-align: center;
  border-radius: 5px;
}

.categoryButtonUpload {
  width: 100%;
  border: 0px;
  background: green;
  color: #FFF;
  border-radius: 10px;
  padding: 8px;
  margin-top: 30px;
}

.title-page {
  width: 100%;
  padding: 20px 8px;
  border-radius: 0px;
  border: 1px solid #e5e5e5;
  margin: 20px 0px;
  background: #F2F2F2;

}

.slug-category {
  width: 100%;
  background: #F2F2F2;
  padding: 20px 8px;
  border-radius: 0px;
  border: 1px solid #e5e5e5;
  margin: 20px 0px;
}


.title-seo-page {
  width: 100%;
  padding: 20px 8px;
  border-radius: 0px;
  border: 1px solid #e5e5e5;
  margin: 20px 0px;
}

.desc-seo-page {
  width: 100%;
  height: 100px;
  padding: 20px 8px;
  border-radius: 0px;
  border: 1px solid #e5e5e5;
  margin: 20px 0px;
}

.key-seo-page {
  width: 100%;
  padding: 20px 8px;
  border-radius: 0px;
  border: 1px solid #e5e5e5;
  margin: 20px 0px;
}

.seo-example-gen {
  width: 100%;
  height: 150px;
  border-radius: 15px;
  border: 1px solid #e5e5e5;
  padding: 20px;
}

.seo-title-how {
  font-size: 16px;
  font-weight: bold;
  color: #0d6efd;
}

.seo-desc-how {
  font-size: 14px;
  color: #111111;
}

.seo-slug-how {
  font-size: 12px;
  color: #CCCCCC;
}

.statusUpload {
  background: green;
  color: #FFFFFF;
  padding: 6px;
  text-align: center;
  border-radius: 5px;
}

.seoInfo {
  font-size: 12px;
  color: #666;
}

.badSeoInfo {
  color: red;
  font-size: 12px;
  font-weight: bold;
}

.unsetOneProductImage {
  width: 175px;
  border: 0px;
  background: darkviolet;
  color: #FFF;
  border-radius: 10px;
  padding: 8px;
  margin-top: 30px;
}

.unsetOneProductImage:hover {
  background-color: #666666;
}
</style>