import Axios from "axios";

export default {

    state: {
        userInfo:null,
        //apiHeaders:[] loginApi.data,
    },
    mutations: {
        loginUser (state, userData) {
            this.state.userInfo = userData;
            //state.apiHeaders = headersData;
        },
        logoutUser () {

            this.state.userInfo.userInfo.name = "";
            this.state.userInfo.userInfo.origname = "";
            this.state.userInfo.userInfo.ugroup = "";
            this.state.userInfo.token = [];

        }
    },
    actions: {
        async login({ commit }, payload) {

            let axiosConfig = {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': 'http://localhost:8080',
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE'
                }
            }

            const loginApi = await Axios.post(
                'https://api.partner.su/login/',
                { 'username': payload.loginUser, 'password': payload.passwordUser },
                axiosConfig
            );

            commit("loginUser", await loginApi.data);

        },
        logout({ commit }) {
            commit("logoutUser");
        }
    },
    getters: {

    }
}

