<template>
  <div id="orders">
    <div class="row">

      <div class="col-6"><h1>Список заказов</h1></div>
      <div class="col-6">

      </div>
      <div class="row">
        <div v-if="statusDelete" class="statusDelete">{{ statusDelete }}</div>
        <table class="table table-striped table-hover" style="font-size: 12px;">
          <thead>
          <tr>
            <th scope="col">Дата заказа</th>
            <th scope="col">Номер заказа</th>
            <th scope="col">Имя пользователя</th>
            <th scope="col">Телефон</th>
            <th scope="col">Стоимость</th>
            <th scope="col">Просмотр</th>
            <!-- <th scope="col">Удалить</th> -->
          </tr>
          </thead>
          <tbody>
          <tr v-for="order in orders"  :key="order._id">
            <td>{{ order.createdAt }}</td>
            <td>Заказ №: {{ order.number }}</td>
            <td>{{ order.fl_fio }} {{ order.ul_name }}</td>
            <td>{{ order.fl_phone }} {{ order.ul_phone }}</td>
            <td>{{ order.order_price }}р.</td>
            <td><router-link :to="{name: 'edit_order', params: { idOrder: order._id } }"><button class="editOrder" >Посмотреть</button></router-link></td>
            <!-- <td><button class="deleteOrder" @click="deleteOrder(order._id)">Удалить</button></td> -->
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";

export default {
  name: "orders",
  data() {
    return {
      orders: null,
      orders1: "",
      status_delete: ""
    }
  },
  methods: {
    fetchOrders() {
      let myToken = this.$store.state.userInfo.token;

      let axiosConfig = {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': 'http://localhost:8080/',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'Authorization' : `Bearer ${myToken}`
        }
      }

      Axios
          .get('https://api.partner.su/shop/orders/all-orders/', axiosConfig)
          .then((response) => {
            this.orders = response.data.orders_list;
            this.orders1 = response.data.title;
          })
          .catch(error => {
            console.log(error);
            this.errored = true;
          })
          .finally(() => (this.loading = false));
    },
    /*deleteOrder(id) {
      if(confirm("Вы уверены что хотите удалить заказ?")){

        let myToken = this.$store.state.userInfo.token;

        Axios
            .post('http://localhost:3000/orders/delete-order/',
                {
                  'deleteOrderId': id,
                },
                {
                  withCredentials: true,
                  headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': 'http://localhost:3000/',
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Authorization' : `Bearer ${myToken}`
                  }
                }

            )
            .then((response) => {
              this.statusDelete = response.data.answer;
              this.fetchOrders();
            });

      }
    }*/
  },
  created() {
    this.fetchOrders();
  }
}
</script>

<style scoped>
.createOrderButton {
  padding: 8px;
  border:0px;
  border-radius: 10px;
  color: #ffffff;
  background: green;
}
.createOrderButton a {
  color: #ffffff;
  text-decoration: none;
}
.createOrderButton:hover {
  background: darkgreen;
}
.statusDelete {
  background: red;
  color: #FFFFFF;
  padding: 6px;
  text-align: center;
  border-radius: 5px;
}
.deleteOrder {
  background: red;
  color: #FFF;
  border: 0px;
  border-radius: 5px;
  padding: 2px 15px;
  font-weight: normal;
  transition: 0.5s;
}
.editOrder {
  background: dodgerblue;
  border: 0px;
  color: #FFF;
  border-radius: 5px;
  padding: 2px 15px;
  font-weight: normal;
  transition: 0.5s;
}
.deleteOrder:hover {
  background: darkred;
  transition: 0.5s;
}
.editOrder:hover {
  background: darkblue;
  transition: 0.5s;
}
</style>