import Axios from "axios";

export default {

    state: {
        userInfoPagesApi:null,
    },
    mutations: {
        loginUserPage (state, userDataPages) {

             this.state.userInfoPagesApi = userDataPages;

        },
        logoutUserPage () {

            this.state.userInfoPagesApi.userInfo.name = "";
            this.state.userInfoPagesApi.userInfo.origname = "";
            this.state.userInfoPagesApi.userInfo.ugroup = "";
            this.state.userInfoPagesApi.token = [];

        }
    },
    actions: {
        async loginPage({ commit }, payload) {

            let axiosConfig = {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': 'http://localhost:8080',
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE'
                }
            }

            const loginPagesApi = await Axios.post(
                'https://site-api.partner.su/login/',
                { 'username': payload.loginUser, 'password': payload.passwordUser },
                axiosConfig
            );

            commit("loginUserPage", await loginPagesApi.data);

        },
        logoutPage({ commit }) {
            commit("logoutUserPage");
        }
    },
    getters: {

    }
}

