<template>
  <div id="menu_editor">
    <h1>Редактор меню сайта</h1>
    <div class="row">
      <div class="col-12">
        <h5>Добавить элемент в меню</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-8">
        <div class="addMenuElement">
          <input type="text" class="addAnotherElm" v-model="addNameElm" placeholder="Имя элемента">
          <input type="text" class="addAnotherElmUrl" v-model="addURLElm" placeholder="URL">
          <button class="addAnotherElmButton" v-on:click="addMenuElm(addNameElm, addURLElm)">Добавить</button>
        </div>
      </div>
      <div class="col-4">
        <button class="buttonUploadMenu" v-on:click="uploadSiteMenu">Сохранить меню</button>
      </div>
    </div>
    <hr>
    <div class="content-nest">
      <div class="side-by-side">
        <div class="side" style="width:30%;">
          <h5>Страницы</h5>
          <hr>
          <div class="menuItems" v-for="menuitem in nestableItemsFrom" :key="menuitem.headerPage">
            <div class="itemHeader">{{ menuitem.headerPage }}</div>
            <button class="addItemButton" v-on:click="addMenuItemTo(menuitem)">Добавить</button>
          </div>
          <br>
          <h5>Категории</h5>
          <hr>
          <div class="menuItems" v-for="category in categories" :key="category._id">
            <div class="itemHeader">{{ category.headerCategory }}</div>
            <button class="addItemButton" v-on:click="addMenuItemTo(category, 'cat')">Добавить</button>
          </div>
        </div>
        <div class="side" style="width:70%;">
            <VueNestable
                v-model="nestableItemsTo"
                cross-list
                group="cross"
            >
              <VueNestableHandle
                  slot-scope="{ item }"
                  :item="item"
              >
                {{ item.headerPage }} &nbsp; &nbsp; &nbsp;
                <button class="itemDelMenu" v-on:click="deleteMenuItem(item)">Удалить из меню</button>
                <br>
                <div style="font-size:12px; color:#666666">URL: {{ item.slugPage }}</div>
              </VueNestableHandle>
            </VueNestable>
          </div>
        </div>
      </div>
      <br>IndexDelElm:<br>
      {{ indexDelElm }}
      <br>IndexDelElmIn:<br>
      {{ indexDelElmIn }}

      <br>From:<br>
      {{ nestableItemsFrom }}

      <br>
      <br>

      <br>To:<br>
      {{ nestableItemsTo }}

      <br>
      <br>
    </div>
</template>

<!-- Scripts -->

<script>

import Axios from "axios";
import { VueNestable, VueNestableHandle } from 'vue-nestable';

export default {
  name: "menu_editor",
  components: {
    VueNestable,
    VueNestableHandle
  },
  data () {
    return {
      activeURL: false,
      indexDelElm: null,
      indexDelElmIn: null,
      addNameElm: '',
      addURLElm: '',
      categories: null,
      nestableItemsFrom: [],
      nestableItemsFrom2: [
        {
          id: 0,
          name: 'Andy2'
        }, {
          id: 1,
          name: 'Harry2',
          children: [{
            id: 2,
            name: 'David2'
          }]
        }, {
          id: 3,
          name: 'Lisa'
        }
      ],
      nestableItemsTo: [],
    }
  },
  methods: {
    async showSitePage () {
      let myToken = this.$store.state.userInfo.token;

      let axiosConfig = {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': 'http://localhost:3000/',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'Authorization' : `Bearer ${myToken}`
        }
      }

      Axios
          .get('http://localhost:3000/site/all-pages/', axiosConfig)
          .then((response) => {

            response.data.pages_list.forEach(page =>
              this.nestableItemsFrom.push({
                id: page._id,
                headerPage: page.headerPage,
                slugPage: page.slugPage,
                children: [],
              })
            );

          })
          .catch(error => {
            console.log(error);
            this.errored = true;
          })
          .finally(() => (this.loading = false));

    },
    showSiteMenu () {
      let myToken = this.$store.state.userInfo.token;

      let axiosConfig = {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': 'http://localhost:3000/',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'Authorization' : `Bearer ${myToken}`
        }
      }

      Axios
          .get('http://localhost:3000/site/get-site-menu/', axiosConfig)
          .then((response) => {

            this.nestableItemsTo = response.data.menu_list[0].menuData;

          })
          .catch(error => {
            console.log(error);
            this.errored = true;
          })
          .finally(() => (this.loading = false));

    },
    uploadSiteMenu () {
      if(confirm("Сохранить меню?")){

        let myToken = this.$store.state.userInfo.token;

        Axios
            .post('http://localhost:3000/site/upload-site-menu/',
                {
                  'menuData': this.nestableItemsTo,
                },
                {
                  withCredentials: true,
                  headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': 'http://localhost:3000/',
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Authorization' : `Bearer ${myToken}`
                  }
                }

            )
            .then((response) => {
              this.statusDelete = response.data.title;
              //this.fetchPages();
            });
      }
    },
    addMenuElm (name, url) {
      let newID = Math.floor(Math.random() * 99999);

      this.nestableItemsTo.push({
        id: newID,
        headerPage: name,
        slugPage: url,
        children:[],
      });
    },
    addMenuItemTo(menuitem, c) {

      let newID = Math.floor(Math.random() * 99999);

      if( c === 'cat') {

        this.nestableItemsTo.push({
          id: newID,
          baseId: menuitem.id,
          headerPage: menuitem.headerCategory,
          slugPage: menuitem.slugCategory,
          children:[]
        });

      } else {

        this.nestableItemsTo.push({
          id: newID,
          baseId: menuitem.id,
          headerPage: menuitem.headerPage,
          slugPage: menuitem.slugPage,
          children:[]
        });

      }
    },
    FindElement(itemId) {
      for(let i = 0; i < this.nestableItemsTo.length; i++) {

        // Level 1
        if(this.nestableItemsTo[i].id === itemId) {
          this.nestableItemsTo.splice(i, 1);
        }

        // Level 2
        if (this.nestableItemsTo[i].children.length > 0) {
          for(let a = 0; a < this.nestableItemsTo[i].children.length; a++) {

            // Find elm Level 2
            if(this.nestableItemsTo[i].children[a].id === itemId) {
              this.nestableItemsTo[i].children.splice(a, 1);
            }

            // Level 3
            if (this.nestableItemsTo[i].children[a].children.length > 0) {
              for(let x = 0; x < this.nestableItemsTo[i].children[a].children.length; x++) {

                // Find elm Level 3
                if(this.nestableItemsTo[i].children[a].children[x].id === itemId) {
                  this.nestableItemsTo[i].children[a].children.splice(x, 1);
                }

                // Level 4
                if (this.nestableItemsTo[i].children[a].children[x].children.length > 0) {
                  for(let z = 0; z < this.nestableItemsTo[i].children[a].children[x].children.length; z++) {

                    // Find elm Level 4
                    if(this.nestableItemsTo[i].children[a].children[x].children[z].id === itemId) {
                     this.nestableItemsTo[i].children[a].children[x].children.splice(z, 1);
                    }

                    // Level 5
                    if (this.nestableItemsTo[i].children[a].children[x].children[z].children.length > 0) {
                      for (let y = 0; y < this.nestableItemsTo[i].children[a].children[x].children[z].children.length; y++) {

                        // Find elm Level 5
                        if (this.nestableItemsTo[i].children[a].children[x].children[z].children[y].id === itemId) {
                          this.nestableItemsTo[i].children[a].children[x].children[z].children.splice(y, 1);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    deleteMenuItem(item) {
      //let index = this.nestableItemsTo.indexOf(item)
      this.indexDelElm = item.id;
      //this.nestableItemsTo.splice(index, 1);
      this.FindElement(item.id);
    },
    fetchCategoryPages() {
      let myToken = this.$store.state.userInfo.token;

      let axiosConfig = {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': 'http://localhost:3000/',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'Authorization' : `Bearer ${myToken}`
        }
      }

      Axios
          .get('http://localhost:3000/site/all-categories/', axiosConfig)
          .then((response) => {
            this.categories  = response.data.categories_list;
          })
          .catch(error => {
            console.log(error);
            this.errored = true;
          })
          .finally(() => (this.loading = false));
    }
  },
  created() {
    this.showSitePage();
    this.showSiteMenu();
    this.fetchCategoryPages();
  }
}
</script>

<style scoped>
@import '../assets/vue-nestable.css';

/*
* Content and components
*/
.content-nest {
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  overflow-y: scroll;
}

.side-by-side {
  display: flex;
}

.side-by-side .side {
  /*flex: 1;*/
  padding-left: 0em;
  padding-right: 1em;
}

.side-by-side .side + .side {
  padding-left: 1em;
  padding-right: 0;
  border-left: 1px solid #ddd;
}

.nestable-list-empty {
  text-align: center;
}
.menuItems {
  border: 1px solid #e5e5e5;
  padding: 8px 16px;
  margin: 10px 0 0;
}
.addItemButton {
  float: right;
  margin: -3px -8px;
  background: royalblue;
  color: #FFF;
  border-radius: 5px;
  border: 0px;
  font-size: 12px;
  padding: 5px;
  margin: -26px -10px 0;
}
.itemDelMenu {
  float: right;
  margin: -3px -8px;
  background: crimson;
  color: #FFF;
  border-radius: 5px;
  border: 0px;
  font-size: 12px;
  padding: 5px;
}
#spoilerSlug {
  color: #CCC;
  height:0px;
  overflow: hidden;
  transform: translate(0%, 0);
  transition: height 200ms linear;
}
#spoilerSlug.activeUrl {
  height: 30px;
}
#spoiler {
  display: none;
}

.trigger.close {
  display: none;
}

.trigger.open:target {
  display: none;
}

.trigger.open:target + .trigger.close {
  display: inline;
}

.trigger.open:target + .trigger.close + #spoiler:target {
  display: block;
}
.addAnotherElm {
  border: 1px solid #CCC;
  border-radius: 5px;
  padding: 8px 20px;
  margin-right: 20px;
}
.addAnotherElmUrl {
  border: 1px solid #CCC;
  border-radius: 5px;
  padding: 8px 20px;
  margin-right: 20px;
}
.addAnotherElmButton {
  background: forestgreen;
  color: #FFF;
  border: 0px;
  border-radius: 5px;
  padding: 6px;
}
.addAnotherElmButton:hover {
  background: darkgreen;
}
.buttonUploadMenu {
  float: right;
  background: cornflowerblue;
  color: #FFF;
  border: 0px;
  border-radius: 5px;
  padding: 6px;
}
.buttonUploadMenu:hover {
  background: royalblue;
}
</style>