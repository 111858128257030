// Vue and modules
import Vue from 'vue';
import Router from 'vue-router';
import Axios from 'axios';
import {store} from './store/';

// App Components
import App from './App.vue';
import Auth from './components/auth';

// Menu Editor
import menu_editor from "./components/menu_editor";

// Pages
import Pages from './components/pages';
import create_page from './components/create_page';
import edit_page from "./components/edit_page";
import comments_page from "@/components/comments_page";

// Category Pages
import category_pages from "./components/category_pages";
import create_category_pages from "./components/create_category_pages";
import edit_category_pages from "./components/edit_category_pages";

// Shop Categories
import shop_categories from "./components/shop_categories";
import create_shop_category from "./components/create_shop_category";
import edit_shop_category from "./components/edit_shop_category";

// Shop Products
import shop_products from "./components/shop_products";
import create_shop_product from "./components/create_shop_product";
import edit_shop_product from "./components/edit_shop_product";

// Orders
import orders from "./components/orders";
import edit_order from "./components/edit_order";

// Import Bootstrap an BootstrapVue CSS files (order is important)
import { BootstrapVue, IconsPlugin  } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
//import 'animate.css';
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Router);
Vue.use(Axios);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name:'Auth',
      component: Auth,
      props: true,
    },
    {
      path: '/pages',
      name:'Pages',
      component: Pages,
      props: true,
    },
    {
      path: '/edit-page/:idPage',
      name:'edit_page',
      component: edit_page,
      props: true,
    },
    {
      path: '/create-page',
      name:'create_page',
      component: create_page,
      props: true,
    },
    {
      path: '/comments-page',
      name:'comments_page',
      component: comments_page,
      props: true,
    },
    {
      path: '/category-pages',
      name:'category_pages',
      component: category_pages,
      props: true,
    },
    {
      path: '/edit-category-pages/:idCategoryPages',
      name:'edit_category_pages',
      component: edit_category_pages,
      props: true,
    },
    {
      path: '/create-category-pages',
      name:'create_category_pages',
      component: create_category_pages,
      props: true,
    },
    {
      path: '/menu-editor',
      name:'menu_editor',
      component: menu_editor,
      props: true,
    },
    {
      path: '/shop-categories',
      name:'shop_categories',
      component: shop_categories,
      props: true,
    },
    {
      path: '/create-shop-category',
      name:'create_shop_category',
      component: create_shop_category,
      props: true,
    },
    {
      path: '/edit-shop-category/:idShopCategory',
      name:'edit_shop_category',
      component: edit_shop_category,
      props: true,
    },
    {
      path: '/shop-products',
      name:'shop_products',
      component: shop_products,
      props: true,
    },
    {
      path: '/create-shop-product',
      name:'create_shop_product',
      component: create_shop_product,
      props: true,
    },
    {
      path: '/edit-shop-product/:idProduct',
      name:'edit_shop_product',
      component: edit_shop_product,
      props: true,
    },
    {
      path: '/orders',
      name:'orders',
      component: orders,
      props: true,
    },
    {
      path: '/edit-order/:idOrder',
      name:'edit_order',
      component: edit_order,
      props: true,
    },
  ]
})

Vue.config.productionTip = false

window.onload = function () {
  new Vue({
    el: '#app',
    render: h => h(App),
    router,
    store
  }).$mount('#app')
}