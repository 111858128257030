<template>
  <div id="pages">
    <div class="row">
      <div class="col-6"><h1>Список страниц сайта</h1></div>
      <div class="col-6">
        <p style="text-align: right; padding: 10px;">
          <button class="createPageButton">
            <router-link to="/create-page">Создать страницу</router-link>
          </button>
        </p>
      </div>
      <div class="row">
        <div v-if="statusDelete" class="statusDelete">{{ statusDelete }}</div>
        <table class="table table-striped table-hover" style="font-size: 12px;">
          <thead>
          <tr>
            <th scope="col">Категория</th>
            <th scope="col">Заголовок</th>
            <th scope="col">URL</th>
            <th scope="col">Изменить</th>
            <th scope="col">Удалить</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="page in pages"  :key="page._id">
            <td>{{ page.categoryPage }}</td>
            <td>{{ page.headerPage }}</td>
            <td>{{ page.slugPage }}</td>
            <td><router-link :to="{name: 'edit_page', params: { idPage: page._id } }"><button class="editPage" >Изменить</button></router-link></td>
            <td><button class="deletePage" @click="deletePage(page._id)">Удалить</button></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";

export default {
  name: "pages",
  data() {
    return {
      pages: null,
      searchQuery: null,
      searchQueryName: null,
      statusDelete: null,
      AllowOrigin: 'http://localhost:8080',
      api: 'https://site-api.partner.su',
    }
  },
  methods: {
    async fetchPages() {
      let myToken = this.$store.state.userInfo.token;

      let axiosConfig = {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': this.AllowOrigin,
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'Authorization' : `Bearer ${myToken}`
        }
      }

      Axios
          .get(this.api + '/site/all-pages/', axiosConfig)
          .then((response) => {
            this.pages  = response.data.pages_list;
          })
          .catch(error => {
            console.log(error);
            this.errored = true;
          })
          .finally(() => (this.loading = false));
    },
    deletePage(id) {
      if(confirm("Вы уверены что хотите удалить страницу?")){

        let myToken = this.$store.state.userInfo.token;

        Axios
            .post(this.api + '/site/delete-page/',
                {
                  'deletePage': id,
                },
                {
                  withCredentials: true,
                  headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': this.AllowOrigin,
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Authorization' : `Bearer ${myToken}`
                  }
                }

            )
            .then((response) => {
              this.statusDelete = response.data.title;
              this.fetchPages();
            });

      }
    }
  },
  created() {
    this.fetchPages();
  }
}
</script>

<style scoped>
.createPageButton {
  padding: 8px;
  border:0px;
  border-radius: 10px;
  color: #ffffff;
  background: green;
}
.createPageButton a {
  color: #ffffff;
  text-decoration: none;
}
.createPageButton:hover {
  background: darkgreen;
}
.statusDelete {
  background: red;
  color: #FFFFFF;
  padding: 6px;
  text-align: center;
  border-radius: 5px;
}
.deletePage {
  background: red;
  color: #FFF;
  border: 0px;
  border-radius: 5px;
  padding: 2px 15px;
  font-weight: normal;
  transition: 0.5s;
}
.editPage {
  background: dodgerblue;
  border: 0px;
  color: #FFF;
  border-radius: 5px;
  padding: 2px 15px;
  font-weight: normal;
  transition: 0.5s;
}
.deletePage:hover {
  background: darkred;
  transition: 0.5s;
}
.editPage:hover {
  background: darkblue;
  transition: 0.5s;
}
</style>