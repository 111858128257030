<template>

  <div id="shop_categories">

    <div class="row">
      <div class="col-6"><h1>Категории магазина из 1С</h1></div>
      <div class="col-6">

      </div>
      <div class="row">
        <div class="col-12">
          <b>Поиск по имени категории:</b>   <input type="text" class="form-control" v-model="searchQueryName" />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div v-if="statusDelete" class="statusDelete">{{ statusDelete }}</div>
          <table class="table table-striped table-hover" style="font-size: 12px;">
            <thead>
            <tr>
              <th scope="col">Категория</th>
              <th scope="col">URL</th>
              <th scope="col"></th>
              <th scope="col">Изменить</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="category in resultQuery"  :key="category._id">
              <td>{{ category.name }}</td>
              <td>{{ category.slug }}</td>
              <td></td>
              <td><router-link :to="{name: 'edit_shop_category', params: { idShopCategory: category._id } }"><button class="editShopCategory">Изменить</button></router-link></td>
            </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>

  </div>

</template>

<script>
import Axios from "axios";

export default {
  name: "shop_categories",
  data() {
    return {
      shopCategories: null,
      searchQuery: null,
      searchQueryName: null,
      statusDelete: null,
    }
  },
  methods: {
    async fetchShopCategory() {
      let myToken = this.$store.state.userInfo.token;

      let axiosConfig = {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': 'http://localhost:3000/',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'Authorization' : `Bearer ${myToken}`
        }
      }

      Axios
          .get('https://api.partner.su/shop/categories', axiosConfig)
          .then((response) => {
            this.shopCategories  = response.data.categories_list;
          })
          .catch(error => {
            console.log(error);
            this.errored = true;
          })
          .finally(() => (this.loading = false));
    },
    searchByNameCat (cat) {
      return cat.filter((item) => {
        return this.searchQueryName.toLowerCase().split(' ').every(v => item.name.toLowerCase().includes(v))
      })
    },
  },
  computed: {
    resultQuery () {

      let newCat = this.shopCategories;
      // Поиск по имени
      // Поиск по имени
      if (this.searchQueryName) {
        newCat = this.searchByNameCat(newCat)
      }
      return newCat

    },
  },
  created() {
    this.fetchShopCategory();
  }
}
</script>

<style scoped>

.editShopCategory {
  background: dodgerblue;
  border: 0px;
  color: #FFF;
  border-radius: 5px;
  padding: 2px 15px;
  font-weight: normal;
  transition: 0.5s;
}
.editShopCategory:hover {
  background: darkblue;
  transition: 0.5s;
}
</style>