<template>
  <div id="app">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="navcont">
        <a class="navbar-brand" href="#"><img src="https://partner.su/images/Logo_partner_SVG.svg" class="logoblock"></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item">
              <button class="sidebar-button" @click="activeSidebar = !activeSidebar">Боковая панель</button>
            </li>
            <li class="nav-item active">
              <router-link class="nav-link" to="/">Войти</router-link>
            </li>
          </ul>
          <span v-if="this.$store.state.userInfo" style="color:#666;"><b>Пользователь:</b> <b>{{ this.$store.state.userInfo.userInfo.origname }}</b></span>
          <span style="color:#666;"><b>Bitcoin:</b> {{ btc.rate_float }} <b>{{ btc.code }}</b></span>
        </div>
      </div>
    </nav>
    <div class="wrapper">
      <div id="sidebar-block" :class="{ activeSidebar }">
        <div class="sidebar-block d-flex flex-column flex-shrink-0 p-3 bg-light" style="width: 280px;">
          <ul class="nav nav-pills flex-column mb-auto">
            <!--
            <li>
              <a href="#" class="nav-link link-dark">
                Клиенты
              </a>
            </li>
            -->
            <li>
              <router-link class="nav-link" to="/orders">Заказы</router-link>
            </li>
            <li>
              <router-link class="nav-link" to="/comments-page">Комментарии</router-link>
            </li>
            <!--
            <li>
              <a href="#" class="nav-link link-dark">
                Аналитика
              </a>
            </li>
            -->
            <li>
              <router-link class="nav-link" to="/pages">Страницы сайта</router-link>
            </li>
            <li>
              <router-link class="nav-link" to="/category-pages">Категории страниц сайта</router-link>
            </li>
            <!--
            <li>
              <router-link class="nav-link" to="/menu-editor">Редактор меню сайта</router-link>
            </li>
            -->
            <li>
              <router-link class="nav-link" to="/shop-categories">Категории магазина</router-link>
            </li>
            <li>
              <router-link class="nav-link" to="/shop-products">Продукция магазина</router-link>
            </li>
          </ul>
        </div>
      </div>
    <div class="content">
      <div id="file-manager-block" :class="{ activeFile }">
        <File_manager />
      </div>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <router-view />
          </div>
        </div>
        <br><br><br>
      </div>
    </div>
  </div>
  <div class="footer">
    <div class="container">

    </div>
  </div>
  <div class="footercpr">
    Copyright 2022 StarSoft
  </div>
</div>
</template>
<script>

import axios from "axios"
import File_manager from "./components/file_manager";

export default {
  name: 'App',
  components: {
    File_manager,
  },
  data() {
    return {
      section: "home",
      btc:[],
      active: false,
      activeFile: false,
      activeSidebar: true
    }
  },
  methods: {
    async btcInfo() {
      axios
          .get('https://api.coindesk.com/v1/bpi/currentprice.json')
          .then((response) => {
            this.btc = response.data.bpi.USD;
            return this.btc;
          })
    }
  },
  created() {
    this.btcInfo();
  }
}
/*
*
*             <transition mode="out-in"
                        enter-active-class="animate__animated animate__fadeInRight"
                        leave-active-class="animate__animated animate__fadeOutRight">

            </transition>

* */
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 0px;
  overflow-x:hidden;
  min-width: 1500px;
}
h1 {
  font-size: 24px !important;
  padding: 0px 0px;
  margin: 20px 0px;
}
.sidebar-block .router-link-active {
  color: #fff !important;
  background-color: #0d6efd !important;
  border-radius: 5px;
}
.logoblock {
  text-align: left;
  padding: 0px 0px;
  width: 180px;
  background-repeat: no-repeat;
  height: 60px;
  margin-right: 58px;
}
.navbar {
  background: #ffffff !important;
  border-bottom: 1px solid #CCCCCC;
}
.navbar-expand-lg .navbar-collapse {
  justify-content: space-between;
}
.navcont {
  width: 100%;
  display: flex;
  padding: 0px 50px;
}
.nav-item {
  padding: 0px 5px;
}
.block-json-content {
  background:#666;
  width:100%;
  color:#ffc107;
  padding:50px;
  border:1px solid #111111;
}
.wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 1000px;
  align-items: stretch;
}
.content {
  margin-top: 0px;
  padding: 25px;
  width: 100%;
}
.footer {
  background: #e5e5e5;
  width: 100%;
  height:50px;
  padding:15px 0px;
  bottom: 0;
  position: relative;
}
.footercpr {
  background: #CCCCCC;
  width: 100%;
  padding:5px;
  text-align: center;
  bottom: 0;
  position: relative;
}
#file-manager-block {
  width:100%;
  background: #ffffff;
  height:0px;
  overflow: hidden;
  transform: translate(0%, 0);
  transition: height 200ms linear;
  overflow-x: hidden;
}
#file-manager-block.activeFile {
  height: 850px;
  border:1px solid #e5e5e5;
  margin-bottom: 30px;
}
.file-manager-button {
  border: 0px;
  background: deepskyblue;
  color: #FFF;
  border-radius: 10px;
  padding: 8px;
}
#sidebar-block {
  width:400px;
  display: none;
  background: #ffffff;
  height:0px;
  overflow: scroll;
  transform: translate(0%, 0);
  transition: height 200ms linear;
  overflow-x: hidden;
}
#sidebar-block.activeSidebar {
  height: 100%;
  display: contents;
  border:1px solid #e5e5e5;
  margin-bottom: 30px;
  padding:15px;
}
.sidebar-button {
  border: 0px;
  background: orangered;
  color: #FFF;
  border-radius: 10px;
  padding: 8px;
}
.fullscreen-modal .modal-dialog {
  max-width: 100%;
  margin: 0;
  top: 20px;
  bottom: 20px;
  left: 20px;
  right: 20px;
  display: flex;
  position: fixed;
  z-index: 100000;
}
.modal-dialog .close {
  border: none;
  outline: none;
  background-color: transparent;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
