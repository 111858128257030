<template>
  <div id="file-manager">
    <div class="d-flex justify-content-between align-items-center p-2">
      <h5 class="m-0">Добавить медиафайл</h5>
      <input type="file"
             multiple
             class="upload-file-section"
             name="uploaded_file"
             enctype="multipart/form-data"
             id="file"
             ref="file"
             v-on:change="handleFileUpload()"
      />
      <button class="upload-file-button mx-1" v-on:click="submitFile()">Загрузить файл</button>
      <button class="add-editor-button mx-1" v-on:click="insertToTiny()">Вставить в страницу</button>
      <button class="add-image-product-button mx-1" v-on:click="addProductImage()">Изображение анонса</button>
      <button class="delete-file-button mx-1" v-on:click="deleteFile()">Удалить файл</button>
      <img src="../assets/close.svg" alt="close" width="20" @click="$emit('close')" class="cursor-pointer">
    </div>

    <div class="file-manager-panel ">
      <div class="panel-file-control">
        <div class="row">
          <div class="col-4">
            <!--<input class="search-file" v-model="searchQueryName" placeholder="Поиск файла..."/><br><br>-->
          </div>
          <div class="col-2">

          </div>
          <div class="col-2">

          </div>
          <div class="col-2">

          </div>
          <div class="col-2">

          </div>
        </div>
      </div>
    </div>
    <div class="all-uploading-files">
      <div v-for="(file, i) in resultQuery" :key="file._id"
           class="col-2"
      >
        <div class="buttonContainer" v-on:click="check(i)">
          <button class="fileManagerFileButton"
                  v-on:click="selectFiles(file.originalname, file.pathname, file.mimetype)"
                  :class="{'activeSel': file.checked}">
            <img v-if="file.thumb" :src="uploadURL + file.thumb"/>
            <img v-else src="https://site-api.partner.su/uploads/document.jpg" style="width: 100px; height: 100px;"/>
            <p>{{ file.originalname }}</p>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Vue from 'vue';
import Axios from 'axios';

export default {
  name: 'file_manager',
  data() {
    return {
      files: [],
      fileDelivery: null,
      uploadPercentage: 0,
      filesList: [],
      uploadURL: 'https://site-api.partner.su',
      responseData: [],
      selectedFiles: [],
      isActive: false,
      searchQuery: null,
      searchQueryName: null,
      testList: null,
    };
  },
  emits: ['close'],
  props: {
    isShowFileManager: {
      type: Boolean,
    },
    typeStore: {
      type: Number,
      default: 1,
    },
  },
  mounted() {
    this.uploadedFiles();
  },
  methods: {

    // Добавить картинку к товару
    addProductImage() {
      let transportData = this.selectedFiles[0].fileOriginalPath;
      if (this.typeStore === 1) {
        this.$store.dispatch('add_image_to_product', transportData);
      } else {
        this.$store.dispatch('add_image_to_product2', transportData);
      }
      this.$emit('close');
    },

    // Подсветка выбранных файлов
    check(index) {

      // Подсветить выбранный файл
      let file = this.filesList[index];

      if (!('checked' in file)) {
        file.checked = true;
      } else {
        file.checked = !file.checked;
      }

      Vue.set(this.filesList, index, file);
    },
    // Выбор файлов
    selectFiles(filename, filePath, mimetype) {

      let index = this.selectedFiles.findIndex(obj => obj.fileOriginalPath === filePath);

      this.testList = index;

      // Добавить выбранный файл

      if (index === 0 || index > 0) {

        // Удалить из выбранных
        this.selectedFiles.splice(index, 1);
        index = -1;

      } else {

        // Поместить в выбранные
        //this.selectedFiles.push(filePath)
        this.selectedFiles.push({
          fileOriginalPath: filePath,
          mimeType: mimetype,
          fileName: filename,
        });
      }

    },
    // Добавить файл в редактор
    insertToTiny() {

      let filePath = this.selectedFiles[0].fileOriginalPath;
      let mimetype = this.selectedFiles[0].mimeType;
      let filename = this.selectedFiles[0].fileName;

      if (mimetype === 'image/png' || mimetype === 'image/jpg' || mimetype === 'image/jpeg') {

        let content = '<img src="https://site-api.partner.su' + filePath + '" />';
        /*eslint-disable no-undef*/
        tinymce.activeEditor.insertContent(content);
        /*eslint-enable no-undef*/

      } else {

        let contentDocument = '<p><a href="https://site-api.partner.su' + filePath + '">' +
            '<img style="width:50px; height: 50px; float: left; margin-right:10px;" src="https://site-api.partner.su/uploads/document.jpg"/>' +
            '<br><b>Скачать документ:</b>' + filename + '</a></p>';

        /*eslint-disable no-undef*/
        tinymce.activeEditor.insertContent(contentDocument);
        /*eslint-enable no-undef*/

      }
      this.$emit('close');
    },
    // Загрузка файлов
    handleFileUpload() {
      this.files = this.$refs.file.files;
    },
    // Текущие файлы в базе данных
    uploadedFiles() {

      let myToken = this.$store.state.userInfo.token;

      let axiosConfig = {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': 'http://localhost:8080',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'Authorization': `Bearer ${myToken}`,
        },
      };

      Axios
          .get('https://site-api.partner.su/site/files', axiosConfig)
          .then((response) => {
            this.filesList = response.data.files_list;
          })
          .catch(error => {
            console.log(error);
            this.errored = true;
          })
          .finally(() => (this.loading = false));

    },
    submitFile() {

      let myToken = this.$store.state.userInfo.token;

      let formData = new FormData();
      for (const file of this.files) {
        formData.append('files[]', file);
      }

      Axios
          .post('https://site-api.partner.su/site/upload-file/',
              formData,
              {
                withCredentials: true,
                headers: {
                  'Content-Type': 'multipart/form-data',
                  'Access-Control-Allow-Origin': 'http://localhost:8080/',
                  'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                  'Authorization': `Bearer ${myToken}`,
                },
                onUploadProgress: function (progressEvent) {
                  this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
                }.bind(this),
              },
          )
          .then(response => {
            this.responseData = response.data;
            this.uploadedFiles();
            this.fileDelivery = 'Success!';
          })
          .catch(function () {
            this.fileDelivery = 'Failure!';
          });

    },
    // Удаление файлов
    deleteFile() {
      if (confirm('Вы уверены что хотите удалить файл(ы)?')) {

        let myToken = this.$store.state.userInfo.token;

        Axios
            .post('https://site-api.partner.su/site/delete-files/',
                {
                  files_list: this.selectedFiles,
                },
                {
                  withCredentials: true,
                  headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': 'http://localhost:8080/',
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Authorization': `Bearer ${myToken}`,
                  },
                },
            )
            .then(response => {
              this.responseData = response.data;
              this.uploadedFiles();
              this.selectedFiles = [];
              this.fileDelivery = 'Файлы удалены!';
            })
            .catch(function () {
              this.fileDelivery = 'Failure!';
            });
      }
    },
  },
  computed: {
    resultQuery() {
      if (this.searchQuery) {
        return this.filesList.filter((file) => {
          return this.searchQuery.toLowerCase().split(' ').every(v => file._id.toLowerCase().includes(v));
        });
      } else if (this.searchQueryName) {
        return this.filesList.filter((file) => {
          return this.searchQueryName.toLowerCase().split(' ').every(v => file.originalname.toLowerCase().includes(v));
        });
      } else {
        return this.filesList;
      }
    },
  },
  created() {
    this.uploadedFiles();
  },
};
</script>

<style scoped>
#file-manager {
  height: 100%;
  overflow: hidden;
}

h3 {
  margin: 20px;
}

.body-file-form {
  margin: 20px;
  text-align: center;
  padding: 20px;
  border: 2px dashed #e5e5e5;
}

.panel-file-control {
  width: 100%;
  margin: 0px;
  text-align: center;
  padding: 0px 0px 5px 0px;
  border-bottom: 1px solid #e5e5e5;
}

.search-file {
  width: 100%;
  border: 1px solid #e5e5e5;
  padding: 6px;
  margin-left: 20px;
}

.add-image-product-button {
  float: right;
  margin: 0px 20px;
  background: darkviolet;
  color: #FFF;
  border-radius: 5px;
  border: 0px;
  font-size: 14px;
  padding: 8px;
}

.add-image-product-button:hover {
  background: grey;
}

.add-editor-button {
  float: right;
  margin: 0px 20px;
  background: green;
  color: #FFF;
  border-radius: 5px;
  border: 0px;
  font-size: 14px;
  padding: 8px;
}

.add-editor-button:hover {
  background: darkgreen;
}

.upload-file-button {
  float: right;
  margin: 0px 20px;
  background: cornflowerblue;
  color: #FFF;
  border-radius: 5px;
  border: 0px;
  font-size: 14px;
  padding: 8px;
}

.upload-file-button:hover {
  background: royalblue;
}

.delete-file-button {
  float: right;
  margin: 0px 20px;
  background: crimson;
  color: #FFF;
  border-radius: 5px;
  border: 0px;
  font-size: 14px;
  padding: 8px;
}

.delete-file-button:hover {
  background: darkred;
}

.all-uploading-files {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  text-align: center;
  overflow: scroll;
  width: 100%;
  height: calc(100% - 50px);
}

.all-uploading-files img {
  width: 100px;
  height: 100px;
}

.fileManagerFileButton {
  width: 150px;
  height: 150px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  white-space: nowrap;
  overflow: hidden;
}

.activeSel {
  background: yellow;
}

.buttonContainer {
  width: 150px;
  height: 150px;
  padding: 0px;
}

.col-2 {
  margin-bottom: 20px;
}
</style>