<template>

  <div id="create_shop_category">
    <div v-if="statusUpload" class="statusUpload">{{ statusUpload }}</div>
    <h1>Создание категории магазина</h1>
    <div class="row">
      <div class="col-10">
        <input v-model="nameShopCategory" class="title-page" type="text" name="title" placeholder="Имя категории"/>
      </div>
      <div class="col-2">
        <button v-on:click="UploadShopCategory()" class="shopCategoryButtonUpload">Создать категорию</button>
      </div>
    </div>
    <div class="row">
      <div class="col-10">
        <input v-model="slugShopCategory" class="slug-shop-category" type="text" name="title" placeholder="URL адрес категории (slug)"/>
      </div>
      <div class="col-2">
        <select v-model="subShopCategory" class="subShopCategory" name="categorySub">
          <option value="Верхний уровень">Верхний уровень</option>
          <!-- Здесь перебор под-категорий !!! -->
        </select>
        <br>
      </div>
    </div>
    <h3>Контент категории</h3>
    <editor v-model="contentShopCategory"
            api-key="3617hroihe3jmdthj5q7e4rq6ujlc3c9avxp9s9haxi5asd9"
            :init="{
         height: 500,
         language: 'ru',
         menubar: true,
         plugins: [
           'advlist autolink lists link images charmap print preview anchor',
           'searchreplace visualblocks code fullscreen',
           'insertdatetime media table paste code help wordcount'
         ],
         toolbar:
           'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help'
       }"
            placeholer="Контент категории"
    /><br>

  </div>

</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import Axios from "axios";

export default {
  name: "create_shop_category",
  data() {
    return {
      nameShopCategory: null,
      slugShopCategory: null,
      subShopCategory: "Верхний уровень",
      parentShopCategory: null,
      contentShopCategory: null,
      seoTitle: null,
      seoDesc: null,
      seoKeywords: null,
      searchQuery: null,
      searchQueryName: null,
      statusUpload:null,
    }
  },
  components: {
    'editor': Editor
  },
  methods: {
    async UploadShopCategory() {

      let myToken = this.$store.state.userInfo.token;

      let parentCategory = "";

      if (this.subShopCategory === 'Верхний уровень') {
        parentCategory = null;
      } else {
        parentCategory = this.subShopCategory;
      }


      // TODO Надо реализовать Path и Level !!!!!!!!!!!!!


      Axios
          .post('http://localhost:3000/shop/add/category',
              {
                'name': this.nameShopCategory,
                'slug': this.slugShopCategory,
                'parent': parentCategory,
                'content': this.contentShopCategory,
                //'seoTitle': this.seoTitle,
                //'seoDesc':this.seoDesc,
                //'seoKeywords': this.seoKeywords,
                //'userCreated': this.$store.state.userInfo.userInfo.origname
              },
              {
                withCredentials: true,
                headers: {
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin': 'http://localhost:3000/',
                  'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                  'Authorization' : `Bearer ${myToken}`
                }
              }

          )
          .then((response) => {
            this.statusUpload = response.data.answer;
          });
    },
  }
}
</script>

<style scoped>
.statusUpload {
  background: green;
  color: #FFFFFF;
  padding: 6px;
  text-align: center;
  border-radius: 5px;
}
.shopCategoryButtonUpload {
  width: 100%;
  border: 0px;
  background: green;
  color: #FFF;
  border-radius: 10px;
  padding: 8px;
  margin-top: 30px;
}
.subShopCategory {
  width: 100%;
  padding: 10px;
  margin-top: 32px;
  border: 1px solid #e5e5e5;
  border-radius: 0px;
}
.title-page {
  width: 100%;
  padding: 20px 8px;
  border-radius: 0px;
  border:1px solid #e5e5e5;
  margin: 20px 0px;
}
.slug-shop-category {
  width: 100%;
  background: #F2F2F2;
  padding: 20px 8px;
  border-radius: 0px;
  border:1px solid #e5e5e5;
  margin: 20px 0px;
}
.title-seo-page {
  width: 100%;
  padding: 20px 8px;
  border-radius: 0px;
  border:1px solid #e5e5e5;
  margin: 20px 0px;
}
.desc-seo-page {
  width: 100%;
  height: 100px;
  padding: 20px 8px;
  border-radius: 0px;
  border:1px solid #e5e5e5;
  margin: 20px 0px;
}
.key-seo-page {
  width: 100%;
  padding: 20px 8px;
  border-radius: 0px;
  border:1px solid #e5e5e5;
  margin: 20px 0px;
}
.seo-example-gen {
  width:100%;
  height: 150px;
  border-radius: 15px;
  border:1px solid #e5e5e5;
  padding: 20px;
}
.seo-title-how {
  font-size: 16px;
  font-weight: bold;
  color:#0d6efd;
}
.seo-desc-how {
  font-size: 14px;
  color: #111111;
}
.seo-slug-how {
  font-size: 12px;
  color:#CCCCCC;
}
</style>
