<template>

  <div id="edit_shop_products">
    <h1>Редактирование товара</h1>
    <div v-if="statusUpload" class="statusUpload">{{ statusUpload }}</div>
    <div class="row">
      <div class="col-10">
        <input v-model="productName" class="title-product" type="text" name="title" placeholder="Наименование товара"/>
      </div>
      <div class="col-2">
        <button v-on:click="UploadEditProduct()" class="productButtonUpload">Обновить товар</button>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        Артикул:<br>
        <input v-model="vendorCode" class="title-product" type="text" name="title" placeholder="Артикул"/>
      </div>
      <div class="col-3">
        Цена:<br>
        <input v-model="productPrice" class="title-product" type="text" name="title" placeholder="Цена"/>
      </div>
      <div class="col-3">
        Цена распродажи:<br>
        <input v-model="productSale" class="title-product" type="text" name="title" placeholder="Цена распродажи"/>
      </div>
      <div class="col-3">
        Тип продукта:
        <input v-model="productType" class="title-product" type="text" name="title" placeholder="Тип продукта"/>
      </div>
    </div>
    <div class="row">
      <div class="col-8">
        <input v-model="productSlug" class="slug-product" type="text" name="title" placeholder="URL адрес страницы продукта (slug)"/>
        <h3>Описание товара</h3>
        <editor v-model="fullDescriptionProduct"
                api-key="3617hroihe3jmdthj5q7e4rq6ujlc3c9avxp9s9haxi5asd9"
                :init="{
         height: 500,
         language: 'ru',
         menubar: true,
         plugins: [
           'advlist autolink lists link images charmap print preview anchor',
           'searchreplace visualblocks code fullscreen',
           'insertdatetime media table paste code help wordcount'
         ],
         toolbar:
           'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help'
       }"
                placeholer="Полное описание товара..."
        /><br>

        <h3>Краткое описание товара</h3>
        <textarea v-model="shortDescriptionProduct" class="anons-page" type="text" name="anons" placeholder="Введите краткое описание..."/>
      </div>
      <div class="col-4">
        <div class="h3-sidebar">Категория товара:</div>
        <select v-model="categoryProduct" class="productCategory" name="categoryPage">
          <option v-bind:value="{ id: null, name: 'Без категории' }">Без категории</option>
          <option v-for="category in categories" :key="category._id" v-bind:value="{ id: category._id, name: category.name }">
            {{ category.name }}
          </option>
        </select>
        <br>

        <div class="h3-sidebar">Изображения товара:</div><br>
        <div v-if="this.$store.state.Transport.imagesState[0]">
          <img v-bind:src="'http://localhost:3000' + this.$store.state.Transport.imagesState[0]" style="width: 100%; border:1px solid #e5e5e5; padding:5px;"/>
        </div>
        <br><br>
        <div class="row">
          <div class="col-3" v-for="image in this.$store.state.Transport.imagesState" :key="image" style="width:100px; border:1px solid #e5e5e5; padding:5px; margin:5px;">
            <img v-bind:src="'http://localhost:3000' + image" style="width: 100%;"/>
          </div>
        </div>
        <button v-if="this.$store.state.Transport.imagesState.length" class="unsetOneProductImage" v-on:click="unsetOneProductImage()">Убрать изображение</button>

      </div>

    </div>
  </div>

</template>

<script>

import Axios from "axios";
import Editor from '@tinymce/tinymce-vue';



export default {
  name: "edit_shop_products",
  props: ['idProduct'],
  data() {
    return {
      productId: null,
      productName: null,
      productSlug: null,
      productPrice: null,
      vendorCode: null,
      categories: null,
      categoryProduct: [{
        id: null,
        name: "Без категории"
      }],
      shortDescriptionProduct: null,
      fullDescriptionProduct: null,
      productType: null,
      productSale: null,

      productImages: [],

      statusUpload:null,
    }
  },
  components: {
    'editor': Editor
  },
  methods: {

    addToProductImage() {

      let transportData = this.selectedFiles[0].fileOriginalPath;
      this.$store.dispatch('add_image_to_product', transportData);

    },
    async fetchProduct(id) {
      let myToken = this.$store.state.userInfo.token;

      let axiosConfig = {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': 'http://localhost:3000/',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'Authorization': `Bearer ${myToken}`
        }
      };
      Axios
          .post('http://localhost:3000/shop/one-product/',
              {
                idProduct: id
              },
              axiosConfig
          )
          .then((response) => {
            this.productId = response.data.shop_product_list[0]._id;
            this.productName = response.data.shop_product_list[0].name;
            this.productSlug = response.data.shop_product_list[0].slug;
            this.productType = response.data.shop_product_list[0].attributes[0].product_type;
            this.vendorCode = response.data.shop_product_list[0].vendor_code;
            this.productPrice = response.data.shop_product_list[0].prices[0].retail_price;
            this.productSale = response.data.shop_product_list[0].sale;
            this.fullDescriptionProduct = response.data.shop_product_list[0].full_description;
            this.shortDescriptionProduct = response.data.shop_product_list[0].short_description;
            this.categoryProduct = { id: response.data.shop_product_list[0].parent_id, name: response.data.shop_product_list[0].parent_category };
            this.productImages = response.data.shop_product_list[0].images;
            // Передача картинок в State
            for (let i = 0; i < this.productImages.length; i++) {
              this.$store.dispatch('add_image_to_product', this.productImages[i]);
            }

          })
          .catch(error => {
            console.log(error);
            this.errored = true;
          })
          .finally(() => (this.loading = false));
    },
    async UploadEditProduct() {

      let myToken = this.$store.state.userInfo.token;

      Axios
          .post('http://localhost:3000/shop/edit-product/',
              {

                // TODO Переписать
                'productId': this.productId,
                'product_name': this.productName,
                'parent_category_id': this.categoryProduct.id,
                'parent_category_name': this.categoryProduct.name,
                'product_slug': this.productSlug,
                'product_full_description': this.fullDescriptionProduct,
                'product_short_description': this.shortDescriptionProduct,
                'product_vendor_code': this.vendorCode,
                'product_type':this.productType,
                'product_price': this.productPrice,
                'product_sale': this.productSale,
                'product_images': this.$store.state.Transport.imagesState
                //'userCreated': this.$store.state.userInfo.userInfo.origname
              },
              {
                withCredentials: true,
                headers: {
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin': 'http://localhost:3000/',
                  'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                  'Authorization' : `Bearer ${myToken}`
                }
              }

          )
          .then((response) => {
            this.statusUpload = response.data.answer;
          });
    },
    fetchShopCategories() {
      let myToken = this.$store.state.userInfo.token;

      let axiosConfig = {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': 'http://localhost:3000/',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'Authorization' : `Bearer ${myToken}`
        }
      }

      Axios
          .get('http://localhost:3000/shop/categories', axiosConfig)
          .then((response) => {
            this.categories  = response.data.categories_list;
          })
          .catch(error => {
            console.log(error);
            this.errored = true;
          })
          .finally(() => (this.loading = false));
    },

    // Удалить последнее добавленное изображение
    unsetOneProductImage() {

      this.$store.dispatch('unset_one_image')

    },
    // Удалить изображения (все)
    unsetProductImages() {

      this.$store.dispatch('unset_images')

    },
  },
  created() {
    this.unsetProductImages();
    this.fetchProduct(this.idProduct);
    this.fetchShopCategories();
  }
}
</script>

<style scoped>
.statusUpload {
  background: green;
  color: #FFFFFF;
  padding: 6px;
  text-align: center;
  border-radius: 5px;
}
.unsetOneProductImage {
  width: 175px;
  border: 0px;
  background: darkviolet;
  color: #FFF;
  border-radius: 10px;
  padding: 8px;
  margin-top: 30px;
}
.unsetOneProductImage:hover {
  background-color: #666666;
}
.productButtonUpload {
  width: 100%;
  border: 0px;
  background: green;
  color: #FFF;
  border-radius: 10px;
  padding: 8px;
  margin-top: 30px;
}

.title-product {
  width: 100%;
  padding: 20px 8px;
  border-radius: 0px;
  border:1px solid #e5e5e5;
  margin: 20px 0px;
}
.slug-product {
  width: 100%;
  background: #F2F2F2;
  padding: 20px 8px;
  border-radius: 0px;
  border:1px solid #e5e5e5;
  margin: 20px 0px;
}
.anons-page {
  width: 100%;
  height: 100px;
  padding: 20px 8px;
  border-radius: 0px;
  border:1px solid #e5e5e5;
  margin: 20px 0px;
}
.h3-sidebar {
  margin-top: 20px;
  font-weight: bold;
}
.productCategory {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 20px;
  border: 1px solid #e5e5e5;
  border-radius: 0px;
}
.title-seo-page {
  width: 100%;
  padding: 20px 8px;
  border-radius: 0px;
  border:1px solid #e5e5e5;
  margin: 20px 0px;
}
.desc-seo-page {
  width: 100%;
  height: 100px;
  padding: 20px 8px;
  border-radius: 0px;
  border:1px solid #e5e5e5;
  margin: 20px 0px;
}
.key-seo-page {
  width: 100%;
  padding: 20px 8px;
  border-radius: 0px;
  border:1px solid #e5e5e5;
  margin: 20px 0px;
}
.seo-example-gen {
  width:100%;
  height: 150px;
  border-radius: 15px;
  border:1px solid #e5e5e5;
  padding: 20px;
}
.seo-title-how {
  font-size: 16px;
  font-weight: bold;
  color:#0d6efd;
}
.seo-desc-how {
  font-size: 14px;
  color: #111111;
}
.seo-slug-how {
  font-size: 12px;
  color:#CCCCCC;
}
</style>